import {
  Typography,
  Box,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import statistics from "src/assets/svg-icons/statistics.svg";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetCities } from "src/api/generic";
import { FormData } from "../../constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormModeTypes } from "src/types/generic";
import { useTranslation } from "react-i18next";

const CompanyDetails = ({
  control,
  errors,
  branches,
  mode,
  watch,
}: {
  control: Control<FormData, any>;
  errors: FieldErrors<FormData>;
  branches: any[] | undefined;
  mode: FormModeTypes;
  watch: UseFormWatch<FormData>;
}) => {
  const { data: citiesData } = useGetCities();
  const hasBranchesList = branches && branches?.length > 0;
  const { t } = useTranslation("addSales");
  const { t : generalT  } = useTranslation("general");
  
  return (
    <div>
      <Typography fontSize={"26px"} fontWeight={600}>
        {hasBranchesList
          ? `${mode === "add" ? generalT("add") : t("update") } ${t("salesJourney")} `
          : t("addCompanyDetails")}
      </Typography>
      <Typography color={"textSecondary"}>{t("letKnowYou")}</Typography>
      <Box my={3} display={"flex"} justifyContent={"center"}>
        <img src={statistics} alt="" />
      </Box>
      <form>
        <Stack gap={2} alignItems={"flex-start"}>
          {/* Select branch */}
          {hasBranchesList ? (
            <>
              <FormControl
                fullWidth
                size="small"
                error={!!errors.branch_id}
                disabled={mode === "edit"}
              >
                <InputLabel id="demo-simple-select-label">
                 {generalT("branchLocation")}
                </InputLabel>
                <Controller
                  name="branch_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={generalT("branchLocation")}
                    >
                      {branches?.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={`${id}`}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.branch_id?.message}
                </FormHelperText>
              </FormControl>
              <Stack
                width={"100%"}
                bgcolor={"#E6E6FD"}
                borderRadius={"4px"}
                padding={1}
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
              >
                <InfoOutlinedIcon sx={{ color: "#3333E9" }} />
                <Typography>
                  {t("youCanEditSales")} <br /> {t("WeAdviceYou")}
                </Typography>
              </Stack>
            </>
          ) : (
            <>
              <Controller
                name="company_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("companyName")}
                    {...field}
                    error={!!errors.company_name}
                    helperText={errors.company_name?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
              <Controller
                name="branch_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={generalT("branchName")}
                    {...field}
                    error={!!errors.branch_name}
                    helperText={errors.branch_name?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
              <FormControl fullWidth size="small" error={!!errors.city_id}>
                <InputLabel id="demo-simple-select-label">
                  {generalT("branchLocation")}
                </InputLabel>
                <Controller
                  name="city_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={generalT("branchLocation")}
                    >
                      {citiesData?.data?.cities?.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={`${id}`}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.city_id?.message}
                </FormHelperText>
              </FormControl>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={{ xs: 1, sm: 1, md: 3 }}
                flexWrap={"wrap"}
                width={"100%"}
              >
                <Typography color={"textSecondary"}>
                  {t("areYourRegistered")}
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.has_vat}
                  sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
                >
                  <Controller
                    name="has_vat"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        key={field.value}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              icon={<CircleOutlinedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label={generalT("yes")}
                          sx={{ color: "#98A2B3" }}
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              icon={<CircleOutlinedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label={generalT("no")}
                          sx={{ color: "#98A2B3" }}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>
              <FormHelperText error id="my-helper-text" sx={{ mt: -3, px: 2 }}>
                {errors.has_vat?.message}
              </FormHelperText>
              {watch("has_vat") === "yes" && (
                <Controller
                  name="vat"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={`${generalT("vat")} %`}
                      {...field}
                      error={!!errors.vat}
                      helperText={errors.vat?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </>
          )}
        </Stack>
      </form>
    </div>
  );
};

export default CompanyDetails;
