import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { ReactNode } from "react";

interface IOption {
  label: string;
  value: string;
  icon: ReactNode;
  disabled?: Boolean;
}

interface IProps {
  label?: string;
  options: IOption[];
  value?: string;
  onChange?: (value: string) => void;
}

const CustomRadioGroup = (props: IProps) => {
  const { label, options, value, onChange = () => {} } = props;

  return (
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      sx={{ gap: "10px", width: "100%" }}
      onChange={(e, value) => onChange(value)}
    >
      {options.map((option) => {
        return (
          <FormControlLabel
            disabled={!!option.disabled}
            value={option.value}
            control={
              <Radio
                icon={<CircleOutlinedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            label={
              <Stack direction={"row"} gap={1}>
                {option.icon}
                <Typography color={!option.disabled ? "#101828" : "unset"}>
                  {option.label}
                </Typography>
              </Stack>
            }
            labelPlacement="start"
            sx={{
              color: "#98A2B3",
              bgcolor: option.value === value ? "#FFE6D4" : "#FCFCFD",
              flexGrow: 1,
              border:
                option.value === value
                  ? "1px solid #D0D5DD"
                  : "1px solid #F2F4F7",
              padding: 2,
              borderRadius: "8px",
              justifyContent: "space-between",
              margin: "0",
            }}
          />
        );
      })}
    </RadioGroup>
  );
};

export default CustomRadioGroup;
