import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import LevelTable from "./table";

const Level = () => {
  const { t } = useTranslation("inventory");

  return (
    <Stack spacing={1.5}>
      <LevelTable />
    </Stack>
  );
};

export default Level;
