import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useDeleteCogsMutation } from "src/api/settings/cogs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useTranslation } from "react-i18next";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import COGSAttachmentsPopup from "../../attachments-popup";

const TableActions = ({
  rowId,
  reference,
  refetch,
}: {
  rowId: number;
  reference: string;
  refetch: Function;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { mutate, isPending, status, error } = useDeleteCogsMutation();
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(true);
  };

  const handleCloseAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(false);
  };

  const handleDelete = () => {
    mutate(rowId);
  };

  const navigateToEditPage = () => {
    navigate(`/purchasing/cogs/${rowId}/edit`);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <IconButton onClick={handleOpenAttachmentsModal}>
        <img src={DownloadIcon} alt="" />
      </IconButton>
      <IconButton onClick={navigateToEditPage}>
        <img src={EditIcon} alt="" />
      </IconButton>
      <IconButton onClick={handleOpen}>
        <img src={DeleteIcon} alt="" />
      </IconButton>

      <ConfirmPopup
        open={open}
        item={reference}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />

      {/* attachments popup */}
      {openAttachmentsModal && (
        <COGSAttachmentsPopup
          open={openAttachmentsModal}
          handleClose={handleCloseAttachmentsModal}
          rowId={rowId}
        />
      )}
    </Stack>
  );
};
export default TableActions;
