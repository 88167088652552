import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useGetBranches } from "src/api/generic";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import {
  useDaysOffMutation,
  useGetAllJourneysDateRange,
} from "src/api/add-sales";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";

interface IModel {
  open: boolean;
  handleClose: () => void;
  refetch?: Function;
}

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  dateRange: yup.object().shape({
    start_date: yup.string().required("Required"),
    end_date: yup
      .string()
      .required("Required")
      .test(
        "date-range",
        "Maximum adding sales days is 31 days for each sales journey",
        function (endDate) {
          const startDate = this.parent.start_date;
          if (!startDate || !endDate) return true; // Allow empty values, leave validation to required()

          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);
          const differenceInTime =
            endDateObj.getTime() - startDateObj.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          return differenceInDays <= 31;
        }
      ),
  }),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const AddDaysOffPopup = (props: IModel) => {
  const { open, handleClose, refetch = () => {} } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");

  const { control, handleSubmit, formState, reset, setValue, watch } =
    useForm<FormInputs>({
      mode: "onBlur",
      resolver: yupResolver(schema),
    });

  // APIS
  const { data } = useGetBranches();
  const { mutate, status, error, isPending } = useDaysOffMutation();
  const { data: journeysDateRange, status: getJourneysDateRangeStatus } =
    useGetAllJourneysDateRange({
      branch_id: `${watch("branch_id")}`,
      enabled: !!watch("branch_id"),
    });

  const isOutsideRange = (day: moment.Moment) => {
    if (!!journeysDateRange) {
      const startDate = moment(journeysDateRange?.start_date);
      const endDate = moment(journeysDateRange?.end_date).add(1, "day");
      return day.isBetween(startDate, endDate, null, "[]");
    }
  };

  const onClose = () => {
    reset({
      branch_id: undefined,
      dateRange: {
        start_date: "",
        end_date: "",
      },
    });
    handleClose();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (
      moment(watch("dateRange").start_date).isAfter(
        moment(journeysDateRange?.end_date).add(1, "day")
      ) ||
      moment(watch("dateRange").end_date).isBefore(
        moment(journeysDateRange?.start_date).subtract(1, "day")
      )
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: `${t("yourLastTimeRange")} ${
            journeysDateRange?.start_date || ""
          } ${t("to")} ${journeysDateRange?.end_date || ""}.  ${t(
            "pleaseEnsure"
          )} ${moment(journeysDateRange?.end_date)
            .add(1, "day")
            .format("YYYY-MM-DD")}.`,
        },
      ]);
      return;
    }

    mutate({
      branch_id: data.branch_id,
      start_date: data.dateRange.start_date,
      end_date: data.dateRange.end_date,
    });
  };

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("addDaysOff")}
          </Typography>
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <FormControl
              fullWidth
              size="small"
              error={!!formState.errors.branch_id}
            >
              <InputLabel id="demo-simple-select-label">
                {generalT("branch")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("branch")}
                  >
                    {data?.data?.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={`${id}`}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {formState.errors.branch_id?.message}
              </FormHelperText>
            </FormControl>
            {/* date range */}
            <Box>
              <Controller
                name="dateRange"
                control={control}
                defaultValue={{
                  start_date: "",
                  end_date: "",
                }}
                render={({ field }) => (
                  <DateRangeInput
                    startDate={
                      field.value.start_date
                        ? moment(field.value.start_date)
                        : null
                    } // moment.Moment | null;
                    startDateId={`your_unique_start_date_id`} // moment.Moment | null;
                    endDate={
                      field.value.end_date ? moment(field.value.end_date) : null
                    } // moment.Moment | null;
                    endDateId={`your_unique_end_date_id`} // string;
                    onDatesChange={(arg: {
                      startDate: moment.Moment | null;
                      endDate: moment.Moment | null;
                    }) => {
                      setValue("dateRange", {
                        start_date: arg.startDate?.format(
                          "yyyy-MM-DD"
                        ) as string,
                        end_date: arg.endDate?.format("yyyy-MM-DD") as string,
                      });
                    }}
                    isOutsideRange={isOutsideRange}
                  />
                )}
              />
              <FormHelperText error id="my-helper-text" sx={{ px: 2 }}>
                {formState.errors.dateRange?.start_date?.message ||
                  formState.errors.dateRange?.end_date?.message}
              </FormHelperText>
            </Box>
          </Stack>
          <Stack mt={3} spacing={2} direction={"row"}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={onClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              loading={isPending}
              type="submit"
              variant="contained"
              fullWidth
            >
              {generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddDaysOffPopup;
