import { Box, Paper, Typography } from "@mui/material";
import Equations from "./equations";
import LineChart from "./chart";
import { IMoneyOwnData } from "src/types/dashboard";
import { useTranslation } from "react-i18next";

const MoneyOwnChart = ({ data }: { data: IMoneyOwnData | undefined }) => {
  const { t } = useTranslation("dashboard");
  return (
    <Paper
      variant="outlined"
      sx={{
        boxShadow: " 0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
      }}
    >
      {data && (
        <>
          <Equations data={data} />
          <Box px={3} pt={3} pb={0.5}>
            <Typography
              fontSize={"16px"}
              lineHeight={"22px"}
              textTransform={"capitalize"}
              fontWeight={500}
            >
              {t("moneyOwn")}
            </Typography>
            <LineChart data={data} />
            {/* <Controller /> */}
          </Box>
        </>
      )}
    </Paper>
  );
};
export default MoneyOwnChart;
