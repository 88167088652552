import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHeadText from "./table-head-text";
import TableActions from "./table-actions";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const AggregatorsCostAndFeesTable = ({
  data,
  isFetching,
  refetch,
}: {
  data: any;
  isFetching: boolean;
  refetch: Function;
}) => {
  const emptyDataArr = data?.length === 0;
  const { t } = useTranslation("aggregators");
  const { t: generalT } = useTranslation("general");

  const HeadText = [
    t("reference"),
    t("branch"),
    t("aggName"),
    t("period"),
    t("aggBankFee"),
    t("compensation"),
    t("otherFees"),
    t("tax"),
    t("createdAt"),
    t("createdBy"),
    t("actions"),
  ];
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {HeadText.map((item) => (
              <TableCell
                align="center"
                sx={{
                  bgcolor: "#F9FAFB",
                }}
              >
                <TableHeadText text={item} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isFetching ? (
          <TableLoadingSkeleton rowsLength={15} cellsLength={11} />
        ) : (
          <TableBody>
            {data?.map((row: any) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                key={row.id}
              >
                <TableCell align="center" sx={{}}>
                  {row.reference}
                </TableCell>
                <TableCell align="center">{row.branch.name}</TableCell>
                <TableCell align="center">{row.aggregator?.name}</TableCell>
                <TableCell align="center" sx={{ minWidth: "130px" }}>
                  {row.start_date} {generalT("to")} {row.end_date}
                </TableCell>
                <TableCell align="center">{formatNumber(row.bank_fee)}</TableCell>
                <TableCell align="center">{formatNumber(row.compensation)}</TableCell>
                <TableCell align="center">{formatNumber(row.other_fee)}</TableCell>
                <TableCell align="center">{formatNumber(row.tax)}</TableCell>
                <TableCell align="center" dir="ltr" sx={{ maxWidth: "110px" }}>
                  {row.created_at}
                </TableCell>
                <TableCell align="center">{row.created_by.name}</TableCell>
                <TableCell align="center">
                  <TableActions
                    rowId={row.id}
                    rowRef={row.reference}
                    refetch={refetch}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {emptyDataArr && <NoData />}
    </TableContainer>
  );
};
export default AggregatorsCostAndFeesTable;
