import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Aggregators from "src/assets/img/home/aggregators.png";
import followerImg from "src/assets/svg-icons/home/follower.svg";

const WhatUserNeed = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <Grid
      container
      bgcolor={"#FFF7F7"}
      p={{ xs: "16px 16px 60px 16px", lg: "101px 180px 176px 120px" }}
      spacing={{ xs: 3, lg: 2 }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Typography
          color={"#000"}
          fontSize={{ xs: "24px", md: "32px" }}
          fontWeight={{ xs: 700, md: 800 }}
          lineHeight={{ xs: "35px", md: "48px" }}
          mb={"15px"}
          sx={{ textAlign: { xs: "center", lg: "start" } }}
        >
          {t("noNeedAccountant")}
        </Typography>
        <Typography
          color={"#333"}
          fontSize={{ xs: "16px", md: "20px" }}
          fontWeight={500}
          lineHeight={"34px"}
          sx={{ textAlign: { xs: "center", lg: "start" } }}
        >
          {t("noNeedAccountantNeedAnyone")}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box position={"relative"} width={{ xs: "100%", lg: "fit-content" }}>
          <Box
            width={{ xs: "100%", lg: "715px" }}
            height={{ xs: "100%", lg: "440px" }}
            position={"relative"}
            zIndex={2}
          >
            <img src={Aggregators} alt="" width={"100%"} height={"100%"} />
          </Box>
          <Box
            position={"absolute"}
            bottom={-15}
            right={-52}
            display={{ xs: "none", md: "block" }}
            zIndex={1}
          >
            <img
              src={followerImg}
              alt=""
              style={{
                transform: i18n.language === "en" ? "scaleX(-1)" : "none",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhatUserNeed;
