import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
}

interface IUnitBody {
  id?: number;
  name: string;
}

// units
export const useGetUnitsList = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-items-units-list", params?.page],
    queryFn: async () => {
      return apiClient.get(`/api/units`, { params: params || {} });
    },
  });

export const useAddUnitMutation = () =>
  useMutation<void, APIErrorResponse, IUnitBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/units`, data);
    },
  });

export const useUpdateUnitMutation = () =>
  useMutation<void, APIErrorResponse, IUnitBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/units/${id}`, rest);
    },
  });

export const useDeleteUnitMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/units/${id}`);
    },
  });

// items
export const useGetItemsList = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-items-list", params?.page],
    queryFn: async () => {
      return apiClient.get(`/api/items`, { params: params || {} });
    },
  });

export const useGetSku = () =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-sku"],
    queryFn: async () => {
      return apiClient.get(`/api/items/get-next-sku`);
    },
    gcTime: 0,
  });

export const useAddItemMutation = () =>
  useMutation<void, APIErrorResponse, IUnitBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/items`, data);
    },
  });

export const useUpdateItemMutation = () =>
  useMutation<void, APIErrorResponse, IUnitBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/items/${id}`, rest);
    },
  });

export const useDeleteItemMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/items/${id}`);
    },
  });

export const useGetDeletedItemsList = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-deleted-items-list", params?.page],
    queryFn: async () => {
      return apiClient.get(`/api/items/deleted-items`, {
        params: params || {},
      });
    },
  });


export const useRestoreItemMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.post(`/api/items/restore-item/${id}`);
    },
  });
