import {
  Box,
  Button,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileItem from "src/shared/components/file-item";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useAddOCTransactionFileMutation,
  useDeleteOCTransactionFileMutation,
  useGetOCTransactionFiles,
} from "src/api/operation-cost/transactions";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useEffect, useState } from "react";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { downloadFileFromUrl, formatBytes } from "src/utils";
import { useTranslation } from "react-i18next";

interface IModel {
  open: boolean;
  handleClose: any;
  rowId: number;
}
const AttachmentsPopup = (props: IModel) => {
  const { open, handleClose, rowId } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [file, setFile] = useState();
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  // APIS
  const { data, refetch, isLoading, isFetching } =
    useGetOCTransactionFiles(rowId);
  const {
    mutate: mutateAdd,
    isPending: isPendingAdd,
    status: statusAdd,
    error: errorAdd,
  } = useAddOCTransactionFileMutation();
  const {
    mutate: mutateDelete,
    isPending: isPendingDelete,
    status: statusDelete,
    error: errorDelete,
  } = useDeleteOCTransactionFileMutation();

  useEffect(() => {
    if (statusAdd === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (statusAdd === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: errorAdd?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusAdd]);

  useEffect(() => {
    if (statusDelete === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (statusDelete === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorDelete?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusDelete]);

  useEffect(() => {
    if (!!file) {
      const formData = new FormData();
      formData.append(`invoice_id`, `${rowId}`);
      formData.append(`file`, file);
      mutateAdd(formData);
    }
  }, [file]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="attachments"
      aria-describedby="attachments-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 547 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("attachments")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {isFetching || isPendingAdd || isPendingDelete ? (
          <Stack gap={2}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </Stack>
        ) : (
          <>
            {data?.data.map((attachment: any) => {
              return (
                <FileItem
                  key={attachment.id}
                  name={`${attachment.file.name} .${attachment.file.extension}`}
                  info={formatBytes(attachment.file.size)}
                  onDelete={() => {
                    mutateDelete(attachment.id);
                  }}
                  isDownloadable
                  onDownload={() => {
                    downloadFileFromUrl(
                      attachment.file.url,
                      attachment.file.name
                    );
                  }}
                />
              );
            })}
            {data?.data.length < 3 && (
              <Box>
                <FilePondUploader
                  onUpload={(e) => {
                    setFile(e[0]);
                  }}
                  maxFiles={1}
                  maxFileSize={3}
                  acceptedFileTypes={[
                    "image/*",
                    "application/pdf",
                    "text/csv",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  ]}
                />
              </Box>
            )}
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default AttachmentsPopup;
