import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Summary from "./Summary";
import AggregatorsList from "./aggregators-list";
import Transactions from "./transactions";
import { useMediaQuery } from "@mui/material";
import AggregatorFees from "./aggregator-fees";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} height={"40px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AggregatorsTabs = () => {
  const { t } = useTranslation("aggregators");
  const [value, setValue] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("month").format("yyyy-MM-DD"),
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        bgcolor={"#F9FAFB"}
        p={0.5}
        border={"1px solid  #EAECF0"}
        borderRadius={1}
        width={{ xs: "100%", md: "fit-content" }}
        display={"flex"}
        justifyContent={isSmallScreen ? "center" : ""}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
          sx={{
            minHeight: { xs: "100%", md: "35px" },
            height: { xs: "100%", md: "35px" },
          }}
        >
          <Tab
            disableRipple
            sx={{
              borderRadius: "6px",
              color: "#667085",
              height: "33px",
              minHeight: "33px",
              "&.Mui-selected": {
                bgcolor: "#FFFFFF",
                color: "#344054",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                m: "1px",
              },
            }}
            label={t("summary")}
            {...a11yProps(0)}
          />
          <Tab
            disableRipple
            sx={{
              borderRadius: "6px",
              color: "#667085",
              height: "33px",
              minHeight: "33px",
              "&.Mui-selected": {
                bgcolor: "#FFFFFF",
                color: "#344054",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                m: "1px",
              },
            }}
            label={t("aggList")}
            {...a11yProps(1)}
          />
          <Tab
            disableRipple
            sx={{
              borderRadius: "6px",
              color: "#667085",
              height: "33px",
              minHeight: "33px",
              "&.Mui-selected": {
                bgcolor: "#FFFFFF",
                color: "#344054",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                m: "1px",
              },
            }}
            label={t("transactions")}
            {...a11yProps(2)}
          />
          <Tab
            disableRipple
            sx={{
              borderRadius: "6px",
              color: "#667085",
              height: "33px",
              minHeight: "33px",
              "&.Mui-selected": {
                bgcolor: "#FFFFFF",
                color: "#344054",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                m: "1px",
              },
            }}
            label={t("aggsFeesCost")}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Summary dateRange={dateRange} setDateRange={setDateRange} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AggregatorsList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Transactions />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <AggregatorFees />
      </CustomTabPanel>
    </Box>
  );
};

export default AggregatorsTabs;
