import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardIcon from "src/assets/svg-icons/home/card.svg";

interface CardProps {
  title: string;
  subtitle: string;
}
const Card = (props: CardProps) => {
  const { t } = useTranslation("home");
  const { title, subtitle } = props;
  return (
    <Box
      borderRadius={3}
      bgcolor={"#FFF"}
      p={{ xs: 2, sm: "29px 30px 0 24px" }}
      minHeight={"308px"}
    >
      <Box
        width={"57px"}
        height={"57px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        border={"1px solid rgba(0, 0, 0, 0.03)"}
        bgcolor={"#F9F9FF"}
        borderRadius={"14px"}
      >
        <img src={CardIcon} alt="" />
      </Box>
      <Typography
        fontSize={"20px"}
        fontWeight={700}
        lineHeight={"46px"}
        color={"#2B3674"}
      >
        {t(title)}
      </Typography>
      <Typography
        fontSize={"15px"}
        fontWeight={500}
        lineHeight={"22.5px"}
        color={"#4E4E4E"}
      >
        {t(subtitle)}
      </Typography>
    </Box>
  );
};

export default Card;
