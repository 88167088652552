import React, { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { TextField, Stack, Typography } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useResetPasswordMutation } from "src/api/auth";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface FormInputs {
  password: string;
  password_confirmation: string;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must include at least one letter, one number, and one special character"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const Form: React.FC = () => {
  let { id } = useParams();
  const { t } = useTranslation("auth");

  const navigate = useNavigate();

  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const { mutate, status, isPending, error } = useResetPasswordMutation();

  const { control, handleSubmit, formState } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    mutate({
      ...data,
      token: id || "",
    });
  };

  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("passwordResetSuccessfully"),
        },
      ]);
      navigate("/login");
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3} alignItems={"flex-start"}>
        <Stack gap={"10px"} width={"100%"}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("password")}
                {...field}
                error={!!formState.errors.password}
                helperText={formState.errors.password?.message}
                fullWidth
                size="small"
              />
            )}
          />
          <Typography color="#667085" fontSize="14px">
           {t("useCharactersMix")}.
          </Typography>
        </Stack>

        <Controller
          name="password_confirmation"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("confirmPassword")}
              {...field}
              error={!!formState.errors.password_confirmation}
              helperText={formState.errors.password_confirmation?.message}
              fullWidth
              size="small"
            />
          )}
        />
        <LoadingButton
          loading={isPending}
          fullWidth
          type="submit"
          variant="contained"
        >
           {t("reset")}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Form;
