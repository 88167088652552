import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "./apiClient";
import { APIErrorResponse } from "src/types/generic";
import {
  ForgotPassAPIBody,
  LoginAPIBody,
  LoginAPIResponse,
  ResetPassAPIBody,
  SignupAPIBody,
  SignupAPIResponse,
} from "src/types/auth";

export const useLoginMutation = () =>
  useMutation<LoginAPIResponse, APIErrorResponse, LoginAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/auth/login`, data);
    },
  });

export const useSignupMutation = () =>
  useMutation<SignupAPIResponse, APIErrorResponse, SignupAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/auth/register`, data);
    },
  });

export const useGetDraftCompanyId = () =>
  useQuery<
    any,
    APIErrorResponse,
    {
      data: {
        reference_number: number;
      };
    }
  >({
    queryKey: ["get-draft-company-id"],
    queryFn: async () => {
      return apiClient.get(`/api/companies/get-draft-company`);
    },
  });

export const useForgotPasswordMutation = () =>
  useMutation<any, APIErrorResponse, ForgotPassAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/auth/forget-password`, data);
    },
  });

export const useResetPasswordMutation = () =>
  useMutation<any, APIErrorResponse, ResetPassAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/auth/reset-password`, data);
    },
  });

export const useLogoutMutation = () =>
  useMutation<void, APIErrorResponse, void>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/auth/logout`, data);
    },
  });
