import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import sealIcon from "src/assets/svg-icons/info.svg";
import ReactApexChart from "react-apexcharts";
import { IAccountsCostsChartData } from "src/types/operation-cost";
import { useTranslation } from "react-i18next";

const colors = [
  "#F8962B",
  "#F04438",
  "#BE865D",
  "#2f987a",
  "#998419",
  "#667FE3",
  "#667085",
];
const CostsChart = ({
  data,
}: {
  data: IAccountsCostsChartData | undefined;
}) => {
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const series: number[] = [];
  const labels: string[] = [];
  const { t  } = useTranslation("operationCost");
  const { t : generalT  } = useTranslation("general");
  
  data?.forEach((item: any) => {
    series.push(item.amount);
    labels.push(item.account_name);
  });

  const options: ApexCharts.ApexOptions = {
    noData: {
      text: generalT("noData"),
    },

    colors: colors,
    chart: {
      type: "donut",
      width: 200,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,

            total: {
              show: true,
              label: t("totalCosts"),
              // formatter: (value) => `${formatNumber(value)}`,
            },
          },
        },
      },
    },
    legend: {
      show: true,
    },
    labels: labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        boxShadow: " 0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
      }}
    >
      <Box px={{xs: 2, sm: 3}} pt={3} pb={1.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={"6px"}>
            <Typography
              fontSize={"16px"}
              lineHeight={"22px"}
              textTransform={"capitalize"}
              fontWeight={500}
            >
              {t("allCosts")}
            </Typography>
            <img src={sealIcon} alt="" width={"16px"} />
          </Stack>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={matchesMediumUp ? 600 : 400}
            />
          </div>
        </Box>
      </Box>
    </Paper>
  );
};

export default CostsChart;
