import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { IProfitChartData } from "src/types/dashboard";
import { formatLongNumbers, formatNumber } from "src/utils";
import { useTranslation } from "react-i18next";

const colors = [
  "#344054",
  "#4D4DEC",
  "#667085",
  "#F04438",
  "#73AF00",
  "#FF8B3D",
];

const Chart = ({ data }: { data: IProfitChartData }) => {
  const { t, i18n } = useTranslation("dashboard");
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const chartRef: any = useRef();

  const daysArr: any = [];
  const COGSArr: any = [];
  const netSalesArr: any = [];
  const netProfitArr: any = [];
  const aggDeliveryArr: any = [];
  const aggFeesArr: any = [];
  const operationCostArr: any = [];

  data.days.forEach((item) => {
    daysArr.push(moment(item.day).format(matchesMediumUp ? "D/M" : "DD / MM"));
    COGSArr.push(item.data.cogs);
    netSalesArr.push(item.data.net_sales);
    netProfitArr.push(item.data.net_profit);
    aggDeliveryArr.push(item.data.aggregator_delivery_cost);
    aggFeesArr.push(item.data.aggregator_fees);
    operationCostArr.push(item.data.operation_cost);
  });

  useEffect(() => {
    if (i18n.language === "en") {
      chartRef?.current?.chart.toggleSeries(t("cogs"));
      chartRef?.current?.chart.toggleSeries("Operation Cost");
      chartRef?.current?.chart.toggleSeries(t("aggregatorDeliveryCost"));
      chartRef?.current?.chart.toggleSeries(t("totalAggregatorsFees"));
    }
  }, []);

  return (
    <ReactApexChart
      ref={chartRef}
      options={{
        noData: {
          text: t("noData"),
          style: {
            fontSize: matchesMediumUp ? "15px" : "12px",
          },
        },
        chart: {
          id: "line-chart",
          toolbar: {
            // autoSelected: "zoom",
            show: false, // Set to false to hide the toolbar
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        xaxis: {
          categories: daysArr,
        },
        tooltip: {
          y: {
            formatter: (value: number): string => formatNumber(+value)?.toString(),
          },
        },
        yaxis: {
          labels: {
            formatter: formatLongNumbers,
          },
        },
        markers: {
          size: 4, // Adjust the size of the marker
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        legend: {
          show: true,
          fontSize: "15px",
          // offsetY: matchesSmallUp ? 15 : 0,
          markers: {
            width: 15,
            height: 15,
            strokeWidth: 0,
            radius: 2,
          },
          itemMargin: {
            horizontal: 0,
            vertical: matchesMediumUp ? 20 : 0,
          },
        },
      }}
      series={[
        {
          name: t("netSales"),
          data: netSalesArr,
        },
        {
          name: t("cogs"),
          data: COGSArr,
        },
        {
          name: t("operationCost"),
          data: operationCostArr,
        },
        {
          name: t("aggregatorDeliveryCost"),
          data: aggDeliveryArr,
        },
        {
          name: t("totalAggregatorsFees"),
          data: aggFeesArr,
        },
        {
          name: t("netProfit"),
          data: netProfitArr,
        },
      ]}
      type="line"
      height={350}
      lineWidth={10}
    />
  );
};

export default Chart;
