import { Button, Stack, TablePagination, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import UserTable from "./table";
import AddEditUser from "./add-edit-user";
import { useGetUsers } from "src/api/settings/users";
import { useTranslation } from "react-i18next";

const Users = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const { t  } = useTranslation("settings");

  const { data, isLoading, refetch } = useGetUsers({
    page: pageNumber + 1,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Stack>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("usersList")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          {t("addNewUser")} 
        </Button>
      </Stack>
      <UserTable users={data?.data} isLoading={isLoading} refetch={refetch} />
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {open && <AddEditUser open={open} handleClose={handleClose} refetch={refetch} />}
    </Stack>
  );
};

export default Users;
