import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Stack, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";

const AllTable = ({ toRefetch }: { toRefetch: boolean }) => {
  const { t } = useTranslation("purchasing");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  // APIS
  const { data, isFetching, refetch } = useGetSuppliersList({
    page: pageNumber + 1,
  });

  const emptyDataArr = data?.data.length === 0;

  const HeadText = [
    t("name"),
    t("email"),
    t("accountBank"),
    t("balance"),
    t("contact"),
    t("createdBy"),
    t("createdAt"),
    t("actions"),
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    refetch();
  }, [toRefetch]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell
                  align={"center"}
                  sx={{
                    bgcolor: "#F9FAFB",
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    color={"#475467"}
                    lineHeight={"18px"}
                    fontWeight={500}
                  >
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableLoadingSkeleton rowsLength={15} cellsLength={8} />
          ) : (
            <TableBody>
              {data.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.bank_account}</TableCell>
                  <TableCell align="center">{row.balance}</TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="right">
                    <TableActions
                      row={row}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Stack>
  );
};
export default AllTable;
