import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import Users from "./users";
import Branches from "./branches";
import Roles from "./roles";
import Sales from "./sales";
import PaymentList from "./payment-list";
import PaymentTransactions from "./payment-transction";
import { useTranslation } from "react-i18next";
import Capital from "./capital";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} height={"40px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SettingsTabs = () => {
  const [value, setValue] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");

  const labels = [
    generalT("branch"),
    t("users"),
    t("roles"),
    t("paymentList"),
    t("paymentTransactions"),
    t("sales"),
    t("capital"),
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        bgcolor={"#F9FAFB"}
        p={0.5}
        border={"1px solid  #EAECF0"}
        borderRadius={1}
        width={{ xs: "100%", md: "fit-content" }}
        display={"flex"}
        justifyContent={isSmallScreen ? "center" : ""}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
          sx={{
            minHeight: { xs: "100%", md: "35px" },
            height: { xs: "100%", md: "35px" },
          }}
        >
          {labels.map((label, index) => {
            return (
              <Tab
                disableRipple
                key={index}
                sx={{
                  borderRadius: "6px",
                  color: "#667085",
                  height: "33px",
                  minHeight: "33px",
                  "&.Mui-selected": {
                    bgcolor: "#FFFFFF",
                    color: "#344054",
                    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                    m: "1px",
                  },
                }}
                label={label}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Branches />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Users />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Roles />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <PaymentList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <PaymentTransactions />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Sales />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Capital />
      </CustomTabPanel>
    </Box>
  );
};

export default SettingsTabs;
