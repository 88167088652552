import { Stack } from "@mui/material";
import Header from "./header";
import AggregatorsTabs from "./tabs";

const Aggregators = () => {
  return (
    <Stack spacing={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Header />
      <AggregatorsTabs />
    </Stack>
  );
};

export default Aggregators;
