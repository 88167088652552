import { Box } from "@mui/material";
import Header from "./header";
import Footer from "src/shared/components/footer";
import PublicAppBar from "src/shared/components/public-appbar";
import PublicMobilAppBar from "src/shared/components/public-mobile-appbar";

const PricesPage = () => {
  return (
    <>
      <Box display={{ xs: "none", md: "block" }}>
        <PublicAppBar />
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <PublicMobilAppBar />
      </Box>
      <Header />
      <Footer />
    </>
  );
};

export default PricesPage;
