import * as Yup from "yup";
import * as animationData from "src/assets/animated/loader.json";

// validations
export const schema = Yup.object().shape({
  mode: Yup.string()
    .required("Required")
    .oneOf(["add", "edit"] as const),
  journey_type: Yup.string()
    .required("Required")
    .oneOf(["manual", "foodics"] as const),

  onboarding: Yup.string()
    .required("Required")
    .oneOf(["yes", "no"] as const),

  // company details
  company_name: Yup.string().required("Required"),
  branch_name: Yup.string().required("Required"),
  city_id: Yup.string().required("Required"),
  has_vat: Yup.string().required("You have to select an option"),

  vat: Yup.string().when("has_vat", {
    is: "yes",
    then: (value) => value.required("Required"),
  }),

  branch_id: Yup.string().required("Required"),

  // calculate sales
  orderByItems: Yup.string().required("Required"),
  orderByPayment: Yup.string().required("Required"),
  has_extra_file: Yup.string().when("mode", {
    is: "add",
    then: (value) => value.required("Required"),
  }),
  order: Yup.string().when("has_extra_file", {
    is: "yes",
    then: (value) => value.required("Required"),
    // otherwise: (value) => value.required(),
  }),

  // COGS
  COGS_type: Yup.string()
    .required("Required")
    .oneOf(["percentage", "amount"] as const),

  COGS_value: Yup.number()
    .typeError("Required")
    .required("Required")
    .when("COGS_type", {
      is: "percentage",
      then: (value) => value.max(100, "Max value is 100"),
    }),

  // bank fees
  bank_fee: Yup.string().required("Required"),

  // has aggregators
  has_aggregators: Yup.string().required("You have to select an option"),
  // aggregators
  aggregators: Yup.array()
    .of(
      Yup.object().shape({
        aggregator_name: Yup.string()
          .required("Required")
          // .min(3, "Must be at least 3 characters long"),
          .test(
            "min-3-non-whitespace",
            "Must be at least 3 characters long",
            (value) => !!value && value.trim().length >= 3
          ),
        aggregator_fee: Yup.string()
          .required("Required")
          .test(
            "min-value",
            "Must be positive number",
            (value) => parseFloat(value || "") >= 0
          )
          .test("max-value", "Max is 100", (value) => parseFloat(value) <= 100),
        payments: Yup.array()
          .of(Yup.string().required(""))
          .test(
            "conditionally-required",
            "Select related payments",
            (value: any, context) => {
              const journey =
                context && context.from
                  ? context.from[1]?.value.journey_type
                  : undefined;
              const onboardingJourney =
                context && context.from
                  ? context.from[1]?.value.onboarding
                  : undefined;
              return (
                value?.length > 0 ||
                journey !== "foodics" ||
                onboardingJourney !== "yes"
              );
            }
          ),
        delivery_type: Yup.string()
          .required("Required")
          .oneOf(["amount", "order"] as const),
        delivery_cost: Yup.string().test(
          "min-value",
          "Must be positive number",
          (value) => parseFloat(value || "") >= 0 || value === ""
        ),
        // manual only
        net_sales_with_tax: Yup.mixed().test(
          "conditionally-required",
          "Net sales with tax is required",
          (value: any, context) => {
            const journey =
              context && context.from
                ? context.from[1]?.value.journey_type
                : undefined;
            return value || journey !== "manual";
          }
        ),
        order_quantity: Yup.mixed().test(
          "conditionally-required",
          "Order quantity is required",
          (value: any, context) => {
            const journey =
              context && context.from
                ? context.from[1]?.value.journey_type
                : undefined;
            return value || journey !== "manual";
          }
        ),
        aggregator_id: Yup.string(),
        aggregator_sale_id: Yup.string(),
        payments_Names: Yup.array().of(Yup.string()),
      })
    )
    .required(""),

  //balance
  balance: Yup.array()
    .of(
      Yup.object().shape({
        amount: Yup.string(),
        quantity: Yup.string(),
        id: Yup.string(),
        name: Yup.string(),
        agg_id: Yup.string(),
        payment_method_id: Yup.string(),
        is_branch: Yup.boolean(),
        start_balance: Yup.string(),
        is_new_payment: Yup.boolean(),
      })
    )
    .required("Required"),

  // Manual
  net_sales_with_tax: Yup.string().required("Required"),
  total_order_quantity: Yup.string().required("Required"),
  discount: Yup.string(),
  charge_with_tax: Yup.string(),
  tips: Yup.string(),
  dateRange: Yup.object().shape({
    start_date: Yup.string().required("Required"),
    end_date: Yup.string()
      .required("Required")
      .test(
        "date-range",
        "Maximum adding sales days is 31 days for each sales journey",
        function (endDate) {
          const startDate = this.parent.start_date;
          if (!startDate || !endDate) return true; // Allow empty values, leave validation to required()

          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);
          const differenceInTime =
            endDateObj.getTime() - startDateObj.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          return differenceInDays <= 31;
        }
      ),
  }),

  //Foodics only
  has_jazr_calculations: Yup.string()
    .required("")
    .oneOf(["yes", "no"] as const),

  // Has new payments in foodics files in add sale
  are_payments_new_agg: Yup.string()
    .required("")
    .oneOf(["yes", "no"] as const),
  new_payments_aggregators: Yup.array()
    .of(
      Yup.object().shape({
        aggregator_name: Yup.string()
          .test("conditionally-required", "Required", (value: any, context) => {
            const paymentsAreNewAgg =
              context && context.from
                ? context.from[1]?.value.are_payments_new_agg
                : undefined;

            return value?.length > 0 || paymentsAreNewAgg !== "yes";
          })
          .test(
            "min-3-non-whitespace",
            "Must be at least 3 characters long",
            (value, context) => {
              const paymentsAreNewAgg =
                context && context.from
                  ? context.from[1]?.value.are_payments_new_agg
                  : undefined;
              return (
                (!!value && value.trim().length >= 3) ||
                paymentsAreNewAgg !== "yes"
              );
            }
          ),
        aggregator_fee: Yup.string().test(
          "conditionally-required",
          "Required",
          (value: any, context) => {
            const paymentsAreNewAgg =
              context && context.from
                ? context.from[1]?.value.are_payments_new_agg
                : undefined;

            return value?.length > 0 || paymentsAreNewAgg !== "yes";
          }
        ),
        payments: Yup.array()
          .of(Yup.string().required(""))
          .test(
            "conditionally-required",
            "Select related payments",
            (value: any, context) => {
              const paymentsAreNewAgg =
                context && context.from
                  ? context.from[1]?.value.are_payments_new_agg
                  : undefined;

              return value?.length > 0 || paymentsAreNewAgg !== "yes";
            }
          ),
      })
    )
    .required(""),
});

export interface FormData extends Yup.InferType<typeof schema> {}

export const initSteps = [
  {
    id: "company_details",
    name: "Company details",
    fields: ["company_name", "branch_name", "city_id", "has_vat", "vat"],
  },
  {
    id: "calculate_sales",
    name: "Calculate sales",
    fields: ["orderByItems", "orderByPayment", "has_extra_file", "order"],
  },
  {
    id: "calculate_COGS",
    name: "Calculate COGS",
    fields: ["COGS_value", "COGS_type"],
  },
  {
    id: "bank_fee",
    name: "Bank fee",
    fields: ["bank_fee"],
  },
  {
    id: "has_aggregators",
    name: "Has Aggregators",
    fields: ["has_aggregators"],
  },
  {
    id: "make_balance",
    name: "Make balance",
    fields: [],
  },
  {
    id: "summary",
    name: "Summary",
    fields: [],
  },
];

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
