import { Stack } from "@mui/material";
import CapitalTable from "./table";

const Capital = () => {
  return (
    <Stack spacing={3}>
      <CapitalTable />
    </Stack>
  );
};

export default Capital;
