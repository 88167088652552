import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useNavigate } from "react-router-dom";
import { useDeleteStockCountMutation } from "src/api/inventory/counts";
import { ConfirmPopup } from "src/shared/components/confirm-popup";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t: generalT } = useTranslation("general");

  // APIS
  const { mutate, isPending, status, error } = useDeleteStockCountMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    mutate(row.id);
  };

  const handleNavigate = () => {
    navigate(`/counts/${row.id}/edit`);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Tooltip title={generalT("edit")}>
        <IconButton onClick={handleNavigate}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={generalT("delete")}>
        <IconButton onClick={handleOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title={t("details")}>
        <IconButton>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}

      {/* confirm delete popup */}
      <ConfirmPopup
        open={open}
        item={row.reference}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
