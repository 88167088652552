import { Box, Button, Stack, Typography } from "@mui/material";
import youtubeIcon from "src/assets/svg-icons/aggregators/youtube.svg";
import handshakeIcon from "src/assets/svg-icons/aggregators/handshake.svg";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation("aggregators");

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Stack direction={"row"} spacing={"12px"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"60px"}
            height={"60px"}
            bgcolor={"#D0D5DD"}
            borderRadius={"16px"}
          >
            <img src={handshakeIcon} width={"32px"} alt="" />
          </Box>
          <Box>
            <Typography fontSize={"32px"} lineHeight={"38.4px"}>
              {t("aggs")}
            </Typography>
            <Typography fontSize={"20px"} lineHeight={"24px"} color={"#98A2B3"}>
              {/* Last Updated: 25 Oct */}
            </Typography>
          </Box>
        </Stack>

        <Button
          variant="outlined"
          color="error"
          sx={{ height: "48px", border: "1px solid  #B32318" }}
          startIcon={<img src={youtubeIcon} alt="" width={"34px"} />}
          href="https://www.youtube.com/watch?v=BGo01HEE4mM"
          target="_blank"
        >
          {t("viewHelp")}
        </Button>
      </Box>
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
    </>
  );
};

export default Header;
