import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
}

export const useGetCapitalList = (params?: IParams) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-capita-list"],
    queryFn: async () => {
      return apiClient.get(`/api/capitals`, { params: params ?? {} });
    },
  });

interface IContributorBody {
  id?: number;
  name: string;
  amount: string;
  date: string;
  note?: string;
}

export const useAddCapitalContributorMutation = () =>
  useMutation<void, APIErrorResponse, IContributorBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/capitals`, data);
    },
  });

export const useUpdateCapitalContributorMutation = () =>
  useMutation<void, APIErrorResponse, IContributorBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/capitals/${id}`, rest);
    },
  });

export const useDeleteCapitalMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/capitals/${id}`);
    },
  });
