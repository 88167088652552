import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PricesTabs from "./tabs";

const Header = () => {
  const { t } = useTranslation("prices");

  return (
    <Box
      bgcolor={"#FFF7F7"}
      p={{ xs: "16px 16px 60px 16px", lg: "87px 122px 218px 102px" }}
     
    >
      <Typography
        align="center"
        color={"#000"}
        fontSize={"30px"}
        fontWeight={600}
        lineHeight={"38px"}
        mb={"27px"}
      >
        {t("pricesPackages")}
      </Typography>
      <PricesTabs />
    </Box>
  );
};

export default Header;
