import { Stack, Typography, IconButton, LinearProgress } from "@mui/material";
import XlsxIcon from "src/assets/svg-icons/xlsx_file.svg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const UploadedFile = ({
  name,
  size,
  label,
  handleDelete,
  disabledDelete,
  isLoading,
}: {
  name: string;
  size: string;
  label?: string;
  handleDelete: () => void;
  disabledDelete?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <Stack>
      {label && <Typography mb={1.5}>{label}</Typography>}
      <Stack
        gap={"12px"}
        border="1px solid #EAECF0"
        padding="16px 24px"
        justifyContent={"space-between"}
        alignItems={"center"}
        minWidth={"312px"}
        maxWidth={"312px"}
      >
        <Stack
          direction={"row"}
          gap={"12px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Stack direction={"row"} gap={"12px"}>
            <img width={40} src={XlsxIcon} alt="" />
            <div>
              <Typography fontSize={"14px"}>{name}</Typography>
              <Typography fontSize={"14px"} color={"textSecondary"}>
                {size}
              </Typography>
            </div>
          </Stack>

          <IconButton
            sx={{ p: "0" }}
            onClick={handleDelete}
            disabled={disabledDelete}
          >
            <DeleteOutlinedIcon fontSize="small" color="primary" />
          </IconButton>
        </Stack>

        {isLoading && <LinearProgress sx={{ width: "100%" }} value={0} />}
      </Stack>
    </Stack>
  );
};

export default UploadedFile;
