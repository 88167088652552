import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import systemJazarImg from "src/assets/img/home/system-jazar.png";
import traditionalWayImg from "src/assets/img/home/traditional-way.png";
import VerticalDivider from "src/assets/img/home/vertical-divider.png";
import traditionalFollowerImg from "src/assets/svg-icons/home/traditional-follower.svg";

const JazarSystemDifference = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <Stack
      bgcolor={"#F5F5F5"}
      p={{ xs: "16px 16px 60px 16px", lg: "94px 140px 79px 133px " }}
      spacing={{ xs: 3, lg: "121px" }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <Typography
          fontSize={{ xs: "24px", lg: "32px" }}
          fontWeight={{ xs: 700, lg: 800 }}
          lineHeight={{ xs: "35px", lg: "48px" }}
          color={"#000"}
          align="center"
        >
          {t("differenceTraditionalWayAndJazar")}
        </Typography>
      </Box>
      <Grid container gap={{ xs: 3, lg: 0 }}>
        <Grid item xs={12} lg={5.7} justifyContent={"f"}>
          <Stack spacing={"23px"}>
            <Box position={"relative"}>
              <Box position={"relative"} zIndex={2}>
                <img src={systemJazarImg} alt="" width={"100%"} />
              </Box>
              <Box
                position={"absolute"}
                bottom={-18}
                left={-25}
                display={{ xs: "none", md: "block" }}
                zIndex={1}
              >
                <img
                  src={traditionalFollowerImg}
                  alt=""
                  style={{
                    transform: i18n.language === "en" ? "none" : "scaleX(-1)",
                  }}
                />
              </Box>
            </Box>
            <Typography
              fontSize={"18px"}
              fontWeight={700}
              lineHeight={"27px"}
              color={"#8D8D8D"}
              align="center"
            >
              {t("jazarMethod")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={0} lg={0.6} display={{ xs: "none", lg: "block" }}>
          <Box display={"flex"} justifyContent={"center"} mt={"-32px"}>
            <img src={VerticalDivider} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12} lg={5.7}>
          <Stack spacing={"23px"}>
            <Box position={"relative"}>
              <Box position={"relative"} zIndex={2}>
                <img src={traditionalWayImg} alt="" width={"100%"} />
              </Box>
              <Box
                position={"absolute"}
                bottom={-18}
                right={-25}
                display={{ xs: "none", md: "block" }}
                zIndex={1}
              >
                <img
                  src={traditionalFollowerImg}
                  alt=""
                  style={{
                    transform: i18n.language === "en" ? "scaleX(-1)" : "none",
                  }}
                />
              </Box>
            </Box>

            <Typography
              fontSize={"18px"}
              fontWeight={700}
              lineHeight={"27px"}
              color={"#8D8D8D"}
              align="center"
            >
              {t("traditionalMethod")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default JazarSystemDifference;
