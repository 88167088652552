export const questions = [
  {
    question: "question1",
    answer: "answer1",
  },
  {
    question: "question2",
    answer: "answer2",
  },
  {
    question: "question3",
    answer: "answer3",
  },
  {
    question: "question4",
    answer: "answer4",
  },
  {
    question: "question5",
    answer: "answer5",
  },
  {
    question: "question6",
    answer: "answer6",
  },
  {
    question: "question7",
    answer: "answer7",
  },
  {
    question: "question8",
    answer: "answer8",
  },
  {
    question: "question9",
    answer: "answer9",
  },
  {
    question: "question10",
    answer: "answer10",
  },
  {
    question: "question11",
    answer: "answer11",
  },
];

export const aggregators = [
  "feature4",
  "feature5",
  "feature6",
  "feature7",
  "feature8",
  "feature9",
  "feature10",
  "feature11",
  "feature12",
  "feature13",
];

export const inventory  = [
  "feature14",
  "feature15",
  "feature16",
  "feature17",
  "feature18",
  "feature19",
  "feature20",
]

export const operatingCosts = [
  "feature21",
  "feature22",
  "feature23",
  "feature24",
  "feature25",
  "feature26",
  "feature27",
  "feature28",
  "feature29",
  "feature30",
  "feature31",
];