import { Box } from "@mui/material";
import YouTube from "react-youtube";

const YoutubeViewerBox = ({ videoId }: { videoId: string }) => {
  return (
    <Box
      my={3}
      display={"flex"}
      justifyContent={"center"}
      px={{ xs: "0", md: "70px" }}
    >
      <Box borderRadius={"13px"} overflow={"hidden"} width={"100%"}>
        <YouTube videoId={videoId} opts={{ height: "261", width: "100%" }} />
      </Box>
    </Box>
  );
};

export default YoutubeViewerBox;
