import { Button, Stack, TablePagination, Typography } from "@mui/material";
import TransactionsTable from "./table";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useEffect, useState } from "react";
import FilterDrawer from "./filters";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useGetAggregatorsCostAndFeesList } from "src/api/aggreagators";
import { useTranslation } from "react-i18next";

const AggregatorFees = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [branches, setBranches] = useState<number[]>([]);
  const [aggregators, setAggregators] = useState<number[]>([]);
  const [refNum, setRefNum] = useState("");
  const [hasBankFee, setHasBankFee] = useState("");
  const [hasCompensation, setHasCompensation] = useState("");
  const [hasOtherFee, setHasOtherFee] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("aggregators");

  const { data, refetch, isFetching } = useGetAggregatorsCostAndFeesList({
    ...(branches.length > 0 && { branches: branches }),
    ...(aggregators.length > 0 && { aggregators: aggregators }),
    ...(!!refNum && { reference_number: refNum }),
    ...(hasBankFee !== "" && {
      has_bank_fee: Number(hasBankFee),
    }),
    ...(hasCompensation !== "" && {
      has_compensation: Number(hasCompensation),
    }),
    ...(hasOtherFee !== "" && {
      has_other_fee: Number(hasOtherFee),
    }),
    page: pageNumber + 1,
  });

  useEffect(() => {
    if (!open) {
      refetch();
    }
  }, [open, pageNumber]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  const handleNavigate = () => {
    navigate("/aggregators/add-agg-fees");
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack spacing={3}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("recentAggCost")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Button
            color="tertiary"
            variant="outlined"
            startIcon={<img src={FilterIcon} alt="" />}
            onClick={handleOpen}
          >
            {t("filter")}
          </Button>
          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNavigate}
          >
            {t("addAggCost")}
          </Button>
        </Stack>
        <FilterDrawer
          open={open}
          setBranches={setBranches}
          branches={branches}
          aggregators={aggregators}
          setAggregators={setAggregators}
          refNum={refNum}
          setRefNum={setRefNum}
          hasBankFee={hasBankFee}
          setHasBankFee={setHasBankFee}
          hasOtherFee={hasOtherFee}
          setHasOtherFee={setHasOtherFee}
          hasCompensation={hasCompensation}
          setHasCompensation={setHasCompensation}
          handleClose={handleClose}
        />
      </Stack>
      <TransactionsTable
        data={data?.data}
        refetch={refetch}
        isFetching={isFetching}
      />
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

export default AggregatorFees;
