import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";

export const PublicRoutes: React.FC = () => {
  const [auth] = useRecoilState(authState);
  return !auth.token ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        !auth.user.company || auth.user.company.is_onboarding
          ? "/add-sales"
          : "/dashboard"
      }
    />
  );
};
