import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import AddEditBranch from "../../add-edit-branch";
import { useState } from "react";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <IconButton onClick={handleOpen}>
        <img src={EditIcon} alt="" />
      </IconButton>

      <AddEditBranch
        open={open}
        handleClose={handleClose}
        row={row}
        refetch={refetch}
      />
    </Stack>
  );
};
export default TableActions;
