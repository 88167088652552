import { Box, Paper, Stack, Typography } from "@mui/material";
import Equations from "./equations";
import LineChart from "./chart";
import sealIcon from "src/assets/svg-icons/info.svg";
import { IAggCostChartData } from "src/types/aggregators";
import { useTranslation } from "react-i18next";

const CostChart = ({ data }: { data: IAggCostChartData | undefined }) => {
  const { t } = useTranslation("aggregators");

  return (
    <Paper
      variant="outlined"
      sx={{
        boxShadow: " 0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
      }}
    >
      <Equations data={data} />
      <Box px={3} pt={3} pb={0.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={"6px"}>
            <Typography
              fontSize={"16px"}
              lineHeight={"22px"}
              textTransform={"capitalize"}
              fontWeight={500}
            >
              {t("costChart")}
            </Typography>
            <img src={sealIcon} alt="" width={"16px"} />
          </Stack>
        </Box>
        <LineChart data={data?.days} />
      </Box>
    </Paper>
  );
};
export default CostChart;
