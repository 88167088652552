import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "./card";

const WhyJazrSystem = () => {
  const { t } = useTranslation("home");

  return (
    <Stack
      bgcolor={"#FFF7F7"}
      p={{ xs: "16px 16px 60px 16px", lg: "0 26px 302px 26px" }}
      position={"relative"}
      id={"whyJazrSystem"}
    >
      <Box display={"flex"} justifyContent={"center"} mb={"50px"}>
        <Typography
          fontSize={"32px"}
          fontWeight={800}
          lineHeight={"48px"}
          color={"#000"}
          align="center"
        >
          {t("whyJazrSystem")}
        </Typography>
      </Box>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={3}>
          <Card
            title="blurApplications"
            subtitle="ambiguityExistsDeliveryApp"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="deliveryOffersTrap" subtitle="subscribeDeliveryOffers" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="oneTripNetProfit" subtitle="oneJourneyToNetProfit" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="excelProblems" subtitle="jazarEliminateExcel" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            title="saveAccountantSalary"
            subtitle="noNeedSmartAccountantNeedDataEntry"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card title="operatingCosts" subtitle="allYourOperationalCosts" />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            title="massiveCostSavings"
            subtitle="netProfitWithHighAccuracy"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="ReliabilityReference" subtitle="ReportsAndGraphical" />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default WhyJazrSystem;
