import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  start_date?: string;
  end_date?: string;
  reference_number?: string;
  branches?: number[];
  journeys?: number[];
  show_dayoff?: 1
}

export const useGetSalesList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-sales-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/transactions`, { params });
    },
  });


export const useUpdatePurchaseTaxMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/journeys/${id}`, rest);
    },
  });

export const useDeleteJourneyMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/journeys/${id}`);
    },
  });
