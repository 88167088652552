import { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import moment from "moment";
import DateRangeInput from "src/shared/components/date-range";
import AccountsChart from "./accounts-chart";
import CostsChart from "./costs-chart";
import {
  useGetOCAccountTypesCosts,
  useGetOCAllAccountCosts,
} from "src/api/operation-cost/summary";
import { useTranslation } from "react-i18next";

interface IProps {
  dateRange: {
    startDate: string;
    endDate: string;
  };
  setDateRange: Function;
}

const Summary = ({ dateRange, setDateRange }: IProps) => {
  const [endDateFocused, setEndDateFocused] = useState(false);
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  const { data, refetch, isFetching } = useGetOCAccountTypesCosts({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
  });

  const {
    data: allCostsData,
    refetch: refetchAllCosts,
    isFetching: isFetchingAllCosts,
  } = useGetOCAllAccountCosts({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
  });

  const isOutsideRange = (day: moment.Moment) => {
    // Calculate the difference in days between the start and end dates
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = day ? moment(day) : null;
    const range = startDate && endDate ? endDate.diff(startDate, "days") : null;

    // Return true if the range exceeds 30 days, false otherwise
    return endDateFocused && range !== null && (range < 0 || range > 30);
  };

  return (
    <Stack spacing={3} pb={3}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          mb={{ xs: "24px", md: 0 }}
        >
          {t("OperationCostSummary")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          {/*  Data Select  */}
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null;
              startDateId={`your_unique_start_date_id`} // moment.Moment | null;
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null;
              endDateId={`your_unique_end_date_id`} // string;
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              isOutsideRange={isOutsideRange}
              onFocusChange={(focused: "startDate" | "endDate" | null) => {
                setEndDateFocused(focused === "endDate");
              }}
            />
          </Box>
          {/*  View Select */}
          <Box sx={{ minWidth: 114 }}>
            <Button
              color="tertiary"
              variant="outlined"
              endIcon={<img src={DownloadIcon} alt="" />}
            >
              {generalT("download")}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <AccountsChart data={data?.data} />
      <CostsChart data={allCostsData?.data} />
    </Stack>
  );
};

export default Summary;
