import React from "react";
import { Button, Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import ConfirmedTable from "./table";

const Confirmed = () => {
  const { t } = useTranslation("inventory");

  return (
    <Stack spacing={1.5}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={2}
      >
        <Button
          color="tertiary"
          variant="outlined"
          size="small"
          endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          sx={{ height: "40px" }}
        >
          {t("filter")}
        </Button>

        <Box sx={{ minWidth: 114 }}>
          <LoadingButton
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
            // onClick={handleDownload}
            // loading={isFetchingDownload}
          >
            {t("download")}
          </LoadingButton>
        </Box>
      </Stack>
      <ConfirmedTable />
    </Stack>
  );
};

export default Confirmed;
