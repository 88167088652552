import { LoadingButton } from "@mui/lab";
import {
  Stack,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface IProps {
  name: string;
  onDownload: any;
  isLoading: boolean;
  options?: any[];
  value?: string;
  setValue?: any;
  selectLabel?: string
}

const ReportBox = ({
  name,
  onDownload,
  isLoading,
  options,
  value,
  setValue,
  selectLabel
}: IProps) => {
  const { t } = useTranslation("reports");

  return (
    <Stack
      bgcolor={"#F2F4F7"}
      borderRadius={"8px"}
      overflow={"hidden"}
      position={"relative"}
    >
      <Box
        position={"absolute"}
        top={0}
        right={0}
        bgcolor={"#fff"}
        height={"40px"}
        width={"40px"}
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      />
      <Box minHeight={"100px"} padding={"70px 5px 50px 5px"}>
        <Typography fontSize={"18px"} fontWeight={600} align="center">
          {name}
        </Typography>
      </Box>
      <Stack direction={"row"} gap={1} p={"9px 19px"} bgcolor={"#EAECF0"}>
        {options && options?.length > 0 && (
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {selectLabel}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              input={<OutlinedInput label={selectLabel} />}
              sx={{bgcolor: "#FFF"}}
            >
              {options?.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <LoadingButton
          onClick={onDownload}
          loading={isLoading}
          sx={{ bgcolor: "#F2F4F7" }}
          fullWidth
          variant="outlined"
          color="tertiary"
        >
          {t("download")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ReportBox;
