import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import EquationItem from "src/shared/components/equation-item";
import { IAggCostChartData } from "src/types/aggregators";

const Equations = ({ data }: { data: IAggCostChartData | undefined }) => {
  const { t } = useTranslation("aggregators");
  const EquationItems = [
    {
      title: `${t("sar")} ${data?.aggregator_fee}`,
      subtitle: t("aggFee"),
      subtitleColor: "#98A2B3",
      operator: "+",
    },
    {
      title: `${t("sar")} ${data?.delivery_cost}`,
      subtitle: t("deliveryCost"),
      subtitleColor: "#98A2B3",
      operator: "+",
    },
    {
      title: `${t("sar")} ${data?.bank_fee}`,
      subtitle: t("aggBankFee"),
      subtitleColor: "#98A2B3",
      operator: "+",
    },
    {
      title: `${t("sar")} ${data?.compensation}`,
      subtitle: `${t("compensation")} (${data?.compensation_percentage}%)`,
      subtitleColor: "#98A2B3",
      operator: "+",
    },
    {
      title: `${t("sar")} ${data?.other_fees}`,
      subtitle: t("otherFees"),
      subtitleColor: "#98A2B3",
      operator: "=",
    },
    {
      title: `${t("sar")} ${data?.total_aggregator_cost}`,
      subtitle: t("totalAggCost"),
      subtitleColor: "#98A2B3",
    },
  ];
  return (
    <Stack py={"10px"} bgcolor={"#EAECF0"} direction={"row"} flexWrap={"wrap"}>
      {EquationItems.map((item) => (
        <EquationItem
          title={item.title}
          subtitle={item.subtitle}
          subtitleColor={item.subtitleColor}
          operator={item.operator}
        />
      ))}
    </Stack>
  );
};
export default Equations;
