import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
}

export const useGetUsers = (params?: IParams) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-users"],
    queryFn: async () => {
      return apiClient.get(`/api/users`, { params: params ?? {} });
    },
  });

interface IUserBody {
  id?: number;
  name: string;
  email: string;
  password?: string;
  branches: { id: number; roles: number[] }[];
}

export const useAddUserMutation = () =>
  useMutation<void, APIErrorResponse, IUserBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/users`, data);
    },
  });

export const useUpdateUserMutation = () =>
  useMutation<void, APIErrorResponse, IUserBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/users/${id}`, rest);
    },
  });

export const useDeleteUserMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/users/${id}`);
    },
  });
