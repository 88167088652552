import { Stack } from "@mui/material";
import EquationItem from "src/shared/components/equation-item";
import { IProfitChartData } from "src/types/dashboard";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const Equations = ({ data }: { data: IProfitChartData }) => {
  const { t } = useTranslation("dashboard");

  const EquationItems = [
    {
      title: `${t("sar")} ${formatNumber(data?.net_sales)}`,
      subtitle: t("netSales"),
      subtitleColor: "#98A2B3",
      operator: "-",
      tooltip: t("AllProductsWithoutTax"),
    },
    {
      title: `${t("sar")} ${formatNumber(data?.cogs)}`,
      subtitle: `${t("cogs")} (${(
        (data?.cogs / data?.net_sales) * 100 || 0
      ).toFixed(2)}%)`,
      subtitleColor: "#98A2B3",
      operator: "-",
      tooltip: t("costGoodsSold"),
    },
    {
      title: `${t("sar")} ${formatNumber(data?.aggregator_fees)}`,
      subtitle: `${t("totalAggregatorsFees")} (${(
        (data?.aggregator_fees / data?.net_sales) * 100 || 0
      ).toFixed(2)}%)`,
      subtitleColor: "#98A2B3",
      operator: "=",
      tooltip: `${t("aggregatorFees")} + ${t("aggregatorBankFee")} + ${t(
        "compensation"
      )} + ${t("otherFees")}`,
    },
    {
      title: `${t("sar")} ${formatNumber(data?.gross_profit)}`,
      subtitle: `${t("grossProfit")} (${(
        (data?.gross_profit / data?.net_sales) * 100 || 0
      ).toFixed(2)}%)`,
      subtitleColor: "#98A2B3",
      operator: "-",
      tooltip: `${t("netSales")} - ${t("cogs")} -${t("aggregatorFees")}`,
    },
    {
      title:
        data?.operation_cost > 0
          ? `${formatNumber(data?.operation_cost)}`
          : `+${t("startCalculating")}`,
      subtitle: `${t("operationCost")} (${
        data?.net_profit > 0
          ? ((data?.operation_cost / data?.net_sales) * 100 || 0).toFixed(2)
          : 0
      }%)`,
      subtitleColor: "#3333E9",
      operator: "-",
      iconType: data?.operation_cost > 0 ? "correct" : "incorrect",
      tooltip: `${t("operationFixCost")}+ ${t("operationCostExpenses")}`,
    },
    {
      title: `${t("sar")} ${formatNumber(data?.aggregator_delivery_cost)}`,
      subtitle: `${t("aggregatorDeliveryCost")} (${(
        (data?.aggregator_delivery_cost / data?.net_sales) * 100 || 0
      ).toFixed(2)}%)`,
      subtitleColor: "#98A2B3",
      operator: "=",
      tooltip: t("aggregatorDeliveryCostLikeDelivery"),
    },
    {
      title: `${t("sar")} ${formatNumber(data?.net_profit)}`,
      subtitle: `${t("netProfit")} (${
        data?.net_profit > 0
          ? ((data?.net_profit / data?.net_sales) * 100 || 0).toFixed(2)
          : 0
      }%)`,
      subtitleColor: "#98A2B3",
      tooltip: t("TheNetProfitTheMoney"),
    },
  ];

  return (
    <Stack py={"10px"} bgcolor={"#EAECF0"} direction={"row"} flexWrap={"wrap"}>
      {EquationItems.map((item) => (
        <EquationItem
          title={item.title}
          subtitle={item.subtitle}
          subtitleColor={item.subtitleColor}
          operator={item.operator}
          iconType={item.iconType as "correct" | "incorrect"}
          tooltip={item.tooltip}
        />
      ))}
    </Stack>
  );
};
export default Equations;
