import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
}

interface ICogsBody {
  id?: number;
  branch_id?: number;
  journeys: number[];
  first_count_tax: number;
  first_purchase_tax: number;
  end_count_tax: number;
}

export const useGetCogsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-cogs-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/cogsGroups`, { params });
    },
  });

export const useAddCogsMutation = () =>
  useMutation<void, APIErrorResponse, FormData >({
    mutationFn: async (data) => {
      return apiClient.post(`/api/cogsGroups`, data);
    },
  });

export const useUpdateCogsMutation = () =>
  useMutation<void, APIErrorResponse, ICogsBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/cogsGroups/${id}`, rest);
    },
  });

export const useDeleteCogsMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/cogsGroups/${id}`);
    },
  });

export const useGetCogsById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-agg-by-id"],
    queryFn: async () => {
      return apiClient.get(`/api/cogsGroups/${id}`);
    },
    gcTime: 0,
    enabled,
  });

  // Cogs files
export const useGetCOGSFiles = (id: number) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-COGS-files", id],
    queryFn: async () => {
      return apiClient.get(`/api/cogsGroupFiles?cogs_group_id=${id}`);
    },
    gcTime: 0,
  });

export const useDeleteCOGSFileMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/cogsGroupFiles/${id}`);
    },
  });

export const useAddCOGSFileMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/cogsGroupFiles`, data);
    },
  });