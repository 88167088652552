import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
}

interface IWasteFormulaBody {
  id?: number;
}

export const useGetWasteFormulasList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-waste-formula-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/formulas`, { params });
    },
  });

export const useAddWasteFormulaMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/formulas`, data);
    },
  });

export const useUpdateWasteFormulaMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/formulas/${id}`, rest);
    },
  });

export const useDeleteWasteFormulaMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/formulas/${id}`);
    },
  });
