import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BranchTable from "./table";
import AddEditBranch from "./add-edit-branch";
import { useTranslation } from "react-i18next";

const Branches = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t  } = useTranslation("settings");
  
  return (
    <Stack spacing={1.5}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
        >
          {t("branchesList")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          {t("addNewBranch")}{" "}
        </Button>
      </Stack>
      <AddEditBranch open={open} handleClose={handleClose} />
      <BranchTable />
    </Stack>
  );
};

export default Branches;
