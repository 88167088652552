import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { LoadingButton } from "@mui/lab";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import {
  useAddPurchaseMutation,
  useGetSupplierEvents,
  useUpdatePurchaseMutation,
} from "src/api/purchasing/purchasing";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import AddMoreIcon from "src/assets/svg-icons/settings/add_more.svg";
import { useGetItemsList } from "src/api/purchasing/items";
import InfoBox from "src/shared/components/info-box";
import { ConfirmPopup } from "src/shared/components/confirm-popup";

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  supplier: yup
    .object()
    .shape({
      id: yup.number(),
    })
    .required("Required"),
  amount: yup.string().required("Required"),
  include_tax: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),

  tax: yup.string().required("Required"),
  invoice_number: yup.string(),
  note: yup.string(),
  date: yup.string().required("Required"),
  payments: yup
    .array()
    .of(
      yup.object().shape({
        payment_method_id: yup
          .string()
          .test(
            "payment-method-required",
            "Payment method is required when amount is specified",
            function (value) {
              const { amount } = this.parent; // Access payment_amount in the same object
              if (amount && !value) {
                return false; // Fail if amount exists but payment method is missing
              }
              return true;
            }
          ),
        amount: yup
          .string()
          .test(
            "payment-amount-required",
            "Payment amount is required when payment method is selected",
            function (value) {
              const { payment_method_id } = this.parent; // Access payment_method_id in the same object
              if (payment_method_id && !value) {
                return false; // Fail if payment method exists but amount is missing
              }
              return true;
            }
          ),
      })
    )
    .required("Required"),
  is_payment_advanced: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),
  purchase_event_id: yup.string().when("is_payment_advanced", {
    is: (is_payment_advanced: number) => is_payment_advanced === 1,
    then: (value) => value.required("Required"),
  }),
  purchase_event_amount: yup.string().when("is_payment_advanced", {
    is: (is_payment_advanced: number) => is_payment_advanced === 1,
    then: (value) => value.required("Required"),
  }),
  //items
  items: yup
    .array()
    .of(
      yup.object().shape({
        // item_id: yup.string().required("Required"),
        item: yup
          .object()
          .shape({
            id: yup.string().required("required"),
            sku: yup.string(),
            name: yup.string(),
          })
          .required("Required"),
        quantity: yup.string().required("Required"),
        amount: yup.string().required("Required"),
      })
    )
    .required("Required"),
});

const steps = [
  {
    name: "purchaseDetails",
    fields: [
      "branch_id",
      "supplier",
      "amount",
      "include_tax",
      "tax",
      "invoice_number",
      "note",
      "date",
      "payments",
      "is_payment_advanced",
      "purchase_event_id",
      "purchase_event_amount",
    ],
  },
  {
    name: "purchaseItems",
    fields: ["items"],
  },
];

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  purchaseToEdit?: any;
  refetch: Function;
}

const AddEditTransactionPopup = (props: IProps) => {
  const { open, handleClose, purchaseToEdit, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [files, setFiles] = useState<any>([]);
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const mode = !!purchaseToEdit ? "edit" : "add";
  const [step, setStep] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);

  //RHF
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
    trigger,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      tax: "0",
      payments: [
        {
          payment_method_id: "",
          amount: "",
        },
      ],
    },
  });

  console.log({ errors });

  const { fields, append, remove } = useFieldArray({
    name: "payments",
    control,
  });

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    name: "items",
    control,
  });

  //APIS
  const { data: branchesData } = useGetBranches();
  const { data: suppliersData } = useGetSuppliersList({});
  const { data: paymentsData } = useGetPaymentMethods({
    enabled: !!watch("branch_id"),
    branches: [watch("branch_id")],
  });
  const { mutate, status, isPending, error } = useAddPurchaseMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdatePurchaseMutation();
  const { data: supplierEventsData, isFetching } = useGetSupplierEvents(
    watch("supplier")?.id
  );
  const { data: itemsData, isLoading: isLoadingItems } = useGetItemsList();

  const branchHasItemsFeature = branchesData?.data.find((branch) => {
    return branch.id === +watch("branch_id");
  })?.has_items;

  let totalItemsAmount = 0;

  watch("items")?.forEach((item) => (totalItemsAmount += +item.amount));

  const amountWithoutTax =
    +watch("include_tax") === 1
      ? +(+watch("amount") - +watch("tax")).toFixed(5)
      : +watch("amount");

  const equalItemsAmountToAllAmount = totalItemsAmount === amountWithoutTax;

  const amountWithTax =
    +watch("include_tax") === 1
      ? +(+watch("amount")).toFixed(5)
      : +(+watch("amount") + +watch("tax")).toFixed(5);

  const itemDPercentage = (index: number) => {
    const itemId = watch("items")?.[index]?.item?.id;
    const itemData = itemsData?.data.find(
      (item: any) => `${item.id}` === itemId
    );

    if (
      !itemData ||
      itemData.last_amount === null ||
      itemData.last_quantity === null
    ) {
      return 0;
    }

    const currentAmount = +watch("items")?.[index].amount || 0;
    const currentQuantity = +watch("items")?.[index].quantity || 0;
    const lastAmount = itemData?.last_amount ?? 0;
    const lastQuantity = itemData?.last_quantity ?? 1; // Default to 1 to avoid division by zero

    // Check if currentQuantity or lastQuantity is zero to avoid division by zero
    if (currentQuantity === 0 || lastQuantity === 0) {
      return 0;
    }

    // Calculate percentage change
    const lastAmountPerQuantity = lastAmount / lastQuantity;
    const currentAmountPerQuantity = currentAmount / currentQuantity;

    // Avoid division by zero in percentage calculation
    const percentageChange =
      ((currentAmountPerQuantity - lastAmountPerQuantity) /
        lastAmountPerQuantity) *
      100;

    return percentageChange || 0;
  };

  const onClose = () => {
    handleClose();
    reset();
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleAppendRole = () => {
    append({
      payment_method_id: "",
      amount: "",
    });
  };

  const handleRemovePayment = (index: number) => {
    remove(index);
  };

  const handleAppendItem = () => {
    appendItem({
      amount: "",
      quantity: "",
      item: { id: "" },
    });
  };

  const handleRemoveItem = (index: number) => {
    removeItem(index);
  };

  const goBack = () => {
    if (step === 1) {
      setStep(0);
      return;
    }
    onClose();
  };

  const validate = async () => {
    let stepFields = steps[step].fields;
    const validStep = await trigger(stepFields as any[], { shouldFocus: true });
    return validStep;
  };

  const goNext = async () => {
    const valid = await validate();
    if (!valid) return;
    const data = getValues();
    if (branchHasItemsFeature && step === 0) {
      setStep(1);
      return;
    }
    if (branchHasItemsFeature && step === 1 && data.items.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("addItems"),
        },
      ]);
      return;
    }

    if (branchHasItemsFeature && step === 1 && !equalItemsAmountToAllAmount) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("totalItemsNeedEqualTotalTransactions"),
        },
      ]);
      return;
    }

    console.log({ data });
    const {
      tax,
      amount,
      note,
      invoice_number,
      date,
      include_tax,
      branch_id,
      supplier,
      payments,
      is_payment_advanced,
      purchase_event_id,
      purchase_event_amount,
      items,
    } = data;
    const dataToSend: any = {
      branch_id: +branch_id,
      supplier_id: supplier?.id,
      include_tax: include_tax,
      amount: +include_tax === 1 ? +(+amount - +tax).toFixed(5) : +amount,
      tax: tax,
      ...(invoice_number && {
        invoice_number: +invoice_number,
      }),
      ...(note && { note: note }),
      date: date,
      is_payment_advanced: +is_payment_advanced,
      ...(+data.is_payment_advanced === 1 && {
        purchase_event_id: purchase_event_id,
      }),
      ...(+data.is_payment_advanced === 1 && {
        purchase_event_amount: purchase_event_amount,
      }),
    };
    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (mode === "add") {
      // append payments array
      payments.forEach((payment, index: number) => {
        if (
          payment.payment_method_id &&
          payment.amount &&
          +payment?.amount > 0
        ) {
          formData.append(
            `payments[${index}][payment_method_id]`,
            payment.payment_method_id as string
          );
          formData.append(
            `payments[${index}][amount]`,
            payment.amount as string
          );
        }
      });
      // append items
      if (items.length > 0 && branchHasItemsFeature) {
        items.forEach((item, index: number) => {
          formData.append(`items[${index}][item_id]`, item.item.id);
          formData.append(`items[${index}][total]`, item.amount);
          formData.append(`items[${index}][quantity]`, item.quantity);
          formData.append(
            `items[${index}][discount]`,
            `${itemDPercentage(index).toFixed(5)}`
          );
        });
      }

      mutate(formData);
    } else {
      mutateUpdate({
        ...dataToSend,
        payments: payments,
        items: items,
        id: purchaseToEdit.id,
      });
    }
  };

  useEffect(() => {
    if (+watch("include_tax") === 1) {
      setValue(
        "tax",
        `${(((+getValues("amount") || 0) / 1.15) * 0.15).toFixed(2)}`
      );
    } else if (+watch("include_tax") === 0) {
      setValue("tax", `${((+getValues("amount") || 0) * 0.15).toFixed(2)}`);
    }
  }, [watch("include_tax"), watch("amount")]);

  // reset form with purchase to edit
  useEffect(() => {
    if (!!purchaseToEdit && !!itemsData) {
      console.log({ purchaseToEdit });
      reset({
        branch_id: purchaseToEdit.branch.id,
        // amount: purchaseToEdit.total,
        amount: purchaseToEdit.include_tax
          ? purchaseToEdit.total
          : purchaseToEdit.amount,
        tax: purchaseToEdit.tax,
        include_tax: purchaseToEdit.include_tax ? 1 : 0,
        invoice_number: purchaseToEdit.invoice_number || "",
        payments: purchaseToEdit.payments.map((pay: any) => {
          return {
            payment_method_id: pay.payment_method?.id,
            amount: pay.total,
          };
        }),
        note: purchaseToEdit.note || "",
        date: purchaseToEdit.date,
        supplier: suppliersData?.data.find(
          (supp: any) => supp.id === purchaseToEdit.supplier?.id
        ),
        is_payment_advanced: purchaseToEdit.is_payment_advanced ? 1 : 0,
        purchase_event_id: purchaseToEdit.purchase_event?.id || "",
        purchase_event_amount: purchaseToEdit.purchase_event_amount ?? "",
        items: purchaseToEdit.items.map((item: any) => {
          return {
            // item_id: item.item?.id,
            item: {
              id: item.item?.id,
              sku: item.item?.sku,
              name: item.item?.name,
            },
            quantity: item.quantity,
            amount: item.total,
          };
        }),
      });
    }
  }, [purchaseToEdit, open, suppliersData, itemsData]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  useEffect(() => {
    if (supplierEventsData) {
      let credit =
        supplierEventsData?.data.find(
          (item: any) => item.id === Number(watch("purchase_event_id"))
        )?.remaining ?? 0;
      setValue("purchase_event_amount", credit);
      console.log({ credit });
    }
  }, [watch("purchase_event_id")]);

  let paymentsCredit = 0;
  watch("payments").forEach((pay) => {
    paymentsCredit += Number(pay.amount) || 0;
  });

  let suppEventCredit = Number(watch("purchase_event_amount")) || 0;
  const credit = +(amountWithTax - paymentsCredit - suppEventCredit) || 0;

  const selectedItemsIds = watch("items")?.map((item) => +item.item?.id);

  console.log({ errors }, getValues());

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "90%", md: 900 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {mode === "add" ? t("addNewPurchasing") : t("editPurchasing")}
          </Typography>
          <IconButton onClick={handleOpenConfirm} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form>
          {step === 0 ? (
            <>
              <Stack spacing={2}>
                {/* select Branches */}
                <FormControl fullWidth size="small" error={!!errors.branch_id}>
                  <InputLabel id="demo-simple-select-label">
                    {t("selectBranches")}
                  </InputLabel>
                  <Controller
                    name="branch_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("selectBranches")}
                        disabled={mode === "edit"}
                      >
                        {branchesData?.data?.map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.branch_id?.message}
                  </FormHelperText>
                </FormControl>

                {/* select Suppler */}
                <FormControl fullWidth size="small" error={!!errors.supplier}>
                  <Controller
                    name="supplier"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        size="small"
                        {...field}
                        options={suppliersData?.data}
                        getOptionLabel={(option: any) => option?.name}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            error={!!errors.supplier}
                            helperText={errors.supplier?.message}
                            label={t("selectSuppler")}
                          />
                        )}
                        renderOption={(props, option: any, { selected }) => (
                          <li key={props.id} {...props}>
                            <Typography>{option.name}</Typography>
                          </li>
                        )}
                        onChange={(_, data) => {
                          console.log({ data });
                          field.onChange(data);
                        }}
                      />
                    )}
                  />
                </FormControl>

                {/* Amount */}
                <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
                  <Box sx={{ width: 220 }}>
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          fullWidth
                          label={t("amount")}
                          size="small"
                          {...field}
                          error={!!errors.amount}
                          helperText={errors.amount?.message}
                          InputLabelProps={{
                            shrink: field.value !== undefined ? true : false,
                          }}
                        />
                      )}
                    />
                  </Box>
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.include_tax}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                    }}
                  >
                    <Controller
                      name="include_tax"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={t("includeTax")}
                            sx={{ color: "#98A2B3" }}
                          />
                          <FormControlLabel
                            value="0"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={t("excludeTax")}
                            sx={{ color: "#98A2B3" }}
                          />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.include_tax?.message}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                {/* tax Optional */}
                <Controller
                  name="tax"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("taxOptional")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.tax}
                      helperText={errors.tax?.message}
                    />
                  )}
                />
                {/* invoice number */}
                <Controller
                  name="invoice_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("invoiceNumberOptional")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.invoice_number}
                      helperText={errors.invoice_number?.message}
                    />
                  )}
                />
                {/* is advanced */}
                <Stack direction={"row"} alignItems={"center"} gap={3}>
                  <Typography>{t("hasAdvancePayment")}?</Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.is_payment_advanced}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                    }}
                  >
                    <Controller
                      name="is_payment_advanced"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={generalT("yes")}
                            sx={{ color: "#98A2B3" }}
                          />
                          <FormControlLabel
                            value="0"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={generalT("no")}
                            sx={{ color: "#98A2B3" }}
                          />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.is_payment_advanced?.message}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                {/* Event */}
                {+watch("is_payment_advanced") === 1 && (
                  <Stack gap={1}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.purchase_event_id}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {t("selectTypeOfPrepaid")}
                      </InputLabel>
                      <Controller
                        name="purchase_event_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            key={field.value}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("selectTypeOfPrepaid")}
                          >
                            {supplierEventsData?.data
                              // ?.filter((event:any) => pay.status)
                              .map(({ id, reference }: any) => {
                                return (
                                  <MenuItem key={id} value={`${id}`}>
                                    {reference}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                      <FormHelperText id="my-helper-text">
                        {errors.purchase_event_id?.message}
                      </FormHelperText>
                    </FormControl>
                    <Controller
                      name="purchase_event_amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          label={t("amount")}
                          fullWidth
                          size="small"
                          {...field}
                          error={!!errors.purchase_event_amount}
                          helperText={errors.purchase_event_amount?.message}
                          InputLabelProps={{
                            shrink: field.value !== undefined ? true : false,
                          }}
                        />
                      )}
                    />
                  </Stack>
                )}

                {/* payments */}
                {fields.map((paymentField, index) => {
                  return (
                    <Stack key={paymentField.id} direction={"row"} gap={1}>
                      <FormControl
                        fullWidth
                        size="small"
                        error={
                          errors.payments &&
                          !!errors.payments[index]?.payment_method_id
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          {t("paymentMethodOptional")}
                        </InputLabel>
                        <Controller
                          name={`payments.${index}.payment_method_id`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              key={field.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={t("paymentMethodOptional")}
                              disabled={mode === "edit"}
                            >
                              {paymentsData?.data
                                ?.filter((pay) => pay.status)
                                .map(({ id, name }) => {
                                  return (
                                    <MenuItem key={id} value={`${id}`}>
                                      {name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          )}
                        />
                        <FormHelperText id="my-helper-text">
                          {errors.payments &&
                            errors.payments[index]?.payment_method_id?.message}
                        </FormHelperText>
                      </FormControl>

                      <Controller
                        name={`payments.${index}.amount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            label={t("amount")}
                            variant="outlined"
                            {...field}
                            error={
                              errors.payments &&
                              !!errors.payments[index]?.amount
                            }
                            helperText={
                              errors.payments &&
                              errors.payments[index]?.amount?.message
                            }
                            fullWidth
                            size="small"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            disabled={mode === "edit"}
                          />
                        )}
                      />

                      {mode === "add" && (
                        <IconButton onClick={() => handleRemovePayment(index)}>
                          <img src={DeleteIcon} alt="" />
                        </IconButton>
                      )}
                    </Stack>
                  );
                })}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {mode === "add" && (
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<img src={AddMoreIcon} alt="" />}
                      onClick={handleAppendRole}
                      sx={{ alignSelf: "flex-start" }}
                    >
                      {t("addAnotherMethod")}
                    </Button>
                  )}

                  <Typography>
                    {t("credit")}: {credit} {generalT("sar")}
                  </Typography>
                </Stack>

                {/* note */}
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("notesOptional")}
                      fullWidth
                      size="small"
                      multiline
                      rows={2}
                      maxRows={3}
                      {...field}
                      error={!!errors.note}
                      helperText={errors.note?.message}
                    />
                  )}
                />

                {/* date */}
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      label={t("transactionDateRepeat")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Stack>
              {/* files */}
              {mode === "add" && (
                <Box width={"100%"} mt={2}>
                  <FilePondUploader
                    onUpload={(e) => setFiles(e)}
                    maxFiles={3}
                    maxFileSize={3}
                    acceptedFileTypes={[
                      "image/*",
                      "application/pdf",
                      "text/csv",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                  />
                </Box>
              )}
            </>
          ) : (
            <>
              <Typography fontSize={"14px"} fontWeight={600} mb={3}>
                {t("addItems")}
              </Typography>
              <Stack gap={1.5}>
                {/* items */}
                {itemFields.map((itemField, index) => {
                  return (
                    <Stack
                      key={itemField.id}
                      direction={"row"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <FormControl
                        fullWidth
                        size="small"
                        error={!!errors.supplier}
                      >
                        <Controller
                          name={`items.${index}.item`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              size="small"
                              {...field}
                              options={itemsData?.data.filter(
                                (item: any) =>
                                  !selectedItemsIds?.includes(item.id) ||
                                  item.id === +watch("items")?.[index]?.item
                              )}
                              getOptionLabel={(option: any) =>
                                option?.name || ""
                              }
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  error={!!errors.items?.[index]?.item}
                                  helperText={
                                    errors.items?.[index]?.item?.message
                                  }
                                  label={t("selectSuppler")}
                                />
                              )}
                              renderOption={(
                                props,
                                option: any,
                                { selected }
                              ) => (
                                <li key={props.id} {...props}>
                                  <Typography>{option.name}</Typography>
                                </li>
                              )}
                              onChange={(_, data) => {
                                console.log({ data });
                                field.onChange(data);
                              }}
                            />
                          )}
                        />
                      </FormControl>

                      <TextField
                        sx={{ minWidth: "100px" }}
                        size="small"
                        variant="outlined"
                        label={t("SKU")}
                        value={watch("items")?.[index]?.item?.sku}
                        InputLabelProps={{
                          shrink: !!watch("items")?.[index].item,
                        }}
                        // disabled
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <Controller
                        name={`items.${index}.quantity`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            label={generalT("quantity")}
                            variant="outlined"
                            {...field}
                            error={
                              errors.items && !!errors.items[index]?.quantity
                            }
                            helperText={
                              errors.items &&
                              errors.items[index]?.quantity?.message
                            }
                            fullWidth
                            size="small"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`items.${index}.amount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            label={t("amountTotal")}
                            variant="outlined"
                            {...field}
                            error={
                              errors.items && !!errors.items[index]?.amount
                            }
                            helperText={
                              errors.items &&
                              errors.items[index]?.amount?.message
                            }
                            fullWidth
                            size="small"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      />
                      {/* each item data */}
                      <Box px={"2px"}>
                        <Typography color={"textSecondary"} fontSize={"14px"}>
                          D%
                        </Typography>
                        <Typography
                          color={
                            +itemDPercentage(index) < -2 ||
                            +itemDPercentage(index) > 2
                              ? "error"
                              : "textSecondary"
                          }
                          fontSize={"14px"}
                          fontWeight={700}
                        >
                          {itemDPercentage(index).toFixed(2)}%
                        </Typography>
                      </Box>
                      <Box px={"2px"}>
                        <Typography
                          noWrap
                          color={"textSecondary"}
                          fontSize={"14px"}
                        >
                          {t("unitCost")}
                        </Typography>
                        <Typography
                          color={"textSecondary"}
                          noWrap
                          fontSize={"14px"}
                          fontWeight={700}
                        >
                          {(
                            +watch("items")?.[index].amount /
                            +watch("items")?.[index].quantity
                          ).toFixed(2)}{" "}
                          SAR
                        </Typography>
                      </Box>
                      <Box px={"2px"}>
                        <Typography color={"textSecondary"} fontSize={"14px"}>
                          {generalT("total")}
                        </Typography>
                        <Typography
                          color={"textSecondary"}
                          noWrap
                          fontSize={"14px"}
                          fontWeight={700}
                        >
                          {+watch("items")?.[index].amount} SAR
                        </Typography>
                      </Box>
                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <img src={DeleteIcon} alt="" />
                      </IconButton>
                    </Stack>
                  );
                })}
                <Button
                  variant="text"
                  size="small"
                  startIcon={<img src={AddMoreIcon} alt="" />}
                  onClick={handleAppendItem}
                  sx={{ alignSelf: "flex-start" }}
                >
                  {t("addAnotherItem")}
                </Button>
                <Typography
                  color={equalItemsAmountToAllAmount ? "textPrimary" : "error"}
                >
                  {t("totalItems")}:{" "}
                  <Typography component={"span"} fontWeight={600}>
                    {totalItemsAmount} SAR
                  </Typography>
                </Typography>
                <Typography>
                  {t("totalTransactions")}:{" "}
                  <Typography component={"span"} fontWeight={600}>
                    {amountWithoutTax} SAR
                  </Typography>
                </Typography>
                <InfoBox text={t("totalItemsNeedEqualTotalTransactions")} />
              </Stack>
            </>
          )}

          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={goBack}
            >
              {generalT(step === 1 ? "back" : "cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={goNext}
              loading={isPending || isPendingUpdate}
            >
              {step === 0 && branchHasItemsFeature
                ? t("next")
                : mode === "add"
                ? t("addPurchasing")
                : generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
        {/* confirm close popup */}
        <ConfirmPopup
          open={openConfirm}
          handleClose={handleCloseConfirm}
          handleConfirm={handleClose}
          title={generalT("discard")}
          subtitle={generalT("areYouSureToDiscardChanges")}
          confirmBtnText={generalT("confirm")}
        />
      </Stack>
    </Modal>
  );
};

export default AddEditTransactionPopup;
