import Chart from "react-apexcharts";
import { Paper, Typography } from "@mui/material";
import { ITotalPurchaseData } from "src/types/dashboard";
import { formatLongNumbers, formatNumber } from "src/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";

const PurchaseCharts = ({ data }: { data: ITotalPurchaseData | undefined }) => {
  const daysArr: any = [];
  const valuesArr: any = [];
  const { t } = useTranslation("dashboard");

  data?.days.forEach((item) => {
    daysArr.push(moment(item.day).format("DD / MM"));
    valuesArr.push(item.data.value);
  });

  const chartOptions = {
    noData: {
      text: t("noData"),
    },
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    xaxis: {
      categories: daysArr,
    },
    tooltip: {
      y: {
        formatter: (value: number): string => formatNumber(+value)?.toString(),
      },
    },
    yaxis: {
      labels: {
        formatter: formatLongNumbers,
      },
    },
    colors: ["#FF8B3D"], // Set the line color (in this case, red)
    markers: {
      size: 5, // Adjust the size of the marker
      colors: ["#FF8B3D"],
    },
    stroke: {
      width: 3,
    },
  };

  const chartSeries = [
    {
      name: "value",
      data: valuesArr,
    },
  ];

  return (
    <Paper
      variant="outlined"
      sx={{
        boxShadow: " 0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        p: 3,
      }}
    >
      <Typography
        lineHeight={"22px"}
        textTransform={"capitalize"}
        fontWeight={500}
        color={"#334155"}
        mb={"22px"}
      >
        {t("totalPurchase")}
      </Typography>

      <Chart options={chartOptions} series={chartSeries} type="line" />
    </Paper>
  );
};

export default PurchaseCharts;
