import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableHeadText from "./table-head-text";
import {
  useDownloadPaymentTransList,
  useGetPaymentTransactionsList,
} from "src/api/settings/payments";
import { useEffect, useState } from "react";
import moment from "moment";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import DateRangeInput from "src/shared/components/date-range";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import FilterDrawer from "../filters";
import { useTranslation } from "react-i18next";
import { downloadFile, formatNumber } from "src/utils";
import { LoadingButton } from "@mui/lab";

const PaymentTransactionsTable = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });
  const [open, setOpen] = useState(false);
  const [branches, setBranches] = useState<number[]>([]);
  const [payments, setPayments] = useState<number[]>([]);
  const [refNum, setRefNum] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [type, setType] = useState("all");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");

  const HeadText = [
    generalT("reference"),
    generalT("branch"),
    t("paymentMethodName"),
    generalT("period"),
    t("paymentQnty"),
    t("netPayment"),
    t("relatedToAgg"),
    generalT("createdBy"),
    t("addingSource"),
    generalT("createdAt"),
  ];

  // APIS
  const { data, refetch, isFetching } = useGetPaymentTransactionsList({
    page: pageNumber + 1,
    start_date: dateRange.startDate || undefined,
    end_date: dateRange.endDate || undefined,
    ...(branches.length > 0 && { branches: branches }),
    ...(payments.length > 0 && { payment_methods: payments }),
    ...(!!refNum && { reference_number: refNum }),
    ...(!!supplierId && { supplier_id: supplierId }),
    ...(!!accountId && { account_id: accountId }),
    ...(!!type && type !== "all" && { journey_type: type }),
  });

  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status,
  } = useDownloadPaymentTransList({
    start_date: dateRange.startDate || undefined,
    end_date: dateRange.endDate || undefined,
    ...(branches.length > 0 && { branches: branches }),
    ...(payments.length > 0 && { payment_methods: payments }),
    ...(!!refNum && { reference_number: refNum }),
    ...(!!supplierId && { supplier_id: supplierId }),
    ...(!!accountId && { account_id: accountId }),
    ...(!!type && type !== "all" && { journey_type: type }),
  });

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    refetchDownload();
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  useEffect(() => {
    if (
      !!dateRange.endDate ||
      (dateRange.endDate === undefined && dateRange.startDate === undefined)
    ) {
      refetch();
    }
  }, [dateRange.endDate, dateRange.startDate]);

  useEffect(() => {
    if (!open) {
      refetch();
    }
  }, [open, pageNumber]);

  useEffect(() => {
    if (status === "success" && !!downloadedData) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", "Payment-Transactions");
    }
  }, [isFetchingDownload]);

  return (
    <Box overflow={"auto"} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("paymentTransactions")}
        </Typography>
        <Stack direction={"row"} gap={2}>
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null
              endDateId={`your_unique_end_date_id`}
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              showClearDates
            />
          </Box>
          <Button
            color="tertiary"
            variant="outlined"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
            sx={{ height: "40px" }}
            onClick={handleOpen}
          >
            {generalT("filter")}
          </Button>
          <Box sx={{ minWidth: 114 }}>
            <LoadingButton
              color="tertiary"
              variant="outlined"
              endIcon={<img src={DownloadIcon} alt="" />}
              onClick={handleDownload}
              loading={isFetchingDownload}
            >
              {generalT("download")}
            </LoadingButton>
          </Box>
        </Stack>
      </Stack>

      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell align="center" sx={{ bgcolor: "#F9FAFB" }}>
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableLoadingSkeleton rowsLength={15} cellsLength={10} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center"> {row.reference}</TableCell>
                  <TableCell align="center">{row.branchName}</TableCell>
                  <TableCell align="center">{row.paymentMethod.name}</TableCell>
                  <TableCell align="center" sx={{ fontSize: "12px" }}>
                    {row.start_date !== row.end_date
                      ? `${row.start_date} ${generalT("to")} ${row.end_date}`
                      : row.start_date}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(row.quantity)}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(row.total)}
                  </TableCell>
                  <TableCell align="center">
                    {row.paymentMethod.is_aggregator ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="center">{row.created_by.name}</TableCell>
                  <TableCell align="center">{row.source}</TableCell>
                  <TableCell align="center">
                    {moment(row.created_at, "YYYY-MM-DD HH:mm A").format(
                      "YYYY-MM-DD"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {/* Filter Drawer */}
      <FilterDrawer
        open={open}
        setBranches={setBranches}
        branches={branches}
        payments={payments}
        setPayments={setPayments}
        refNum={refNum}
        setRefNum={setRefNum}
        handleClose={handleClose}
        supplierId={supplierId}
        setSupplierId={setSupplierId}
        accountId={accountId}
        setAccountId={setAccountId}
        type={type}
        setType={setType}
      />
    </Box>
  );
};
export default PaymentTransactionsTable;
