import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import headerImg from "src/assets/img/home/header.png";
import followerImg from "src/assets/svg-icons/home/follower.svg";

const Header = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <Stack
      p={{ xs: "16px 16px 60px 16px", lg: "80px 0 16px 0" }}
      bgcolor={"#FFF7F7"}
      spacing={{ xs: 3, md: "70px" }}
      alignItems={"center"}
    >
      <Typography
        maxWidth={{ xs: "100%", md: "80%", lg: "981px" }}
        fontSize={{ xs: "20px", md: "32px" }}
        fontWeight={{ xs: 500, md: 800 }}
        lineHeight={{ xs: "35px", md: "48px" }}
        color={"#1E1E1E"}
        align="center"
      >
        {t("headerTitle")}
      </Typography>
      <Box
        position={"relative"}
        sx={{ mb: { xs: "", lg: "-80px !important" } }}
      >
        <Box
          width={{ xs: "100%", lg: "1120px" }}
          height={{ xs: "100%", lg: "549px" }}
          position={"relative"}
          zIndex={2}
        >
          <img
            src={i18n.language === "en" ? headerImg : headerImg}
            alt=""
            width={"100%"}
            height={"100%"}
          />
        </Box>
        <Box
          position={"absolute"}
          bottom={-4}
          left={-11}
          display={{ xs: "none", md: "block" }}
          zIndex={1}
        >
          <img src={followerImg} alt="" />
        </Box>
      </Box>
    </Stack>
  );
};

export default Header;
