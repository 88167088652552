import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import AddMoreIcon from "src/assets/svg-icons/settings/add_more.svg";
import AddMoreTertiaryIcon from "src/assets/svg-icons/add_more_tertiary.svg";
import { useGetItemsList } from "src/api/purchasing/items";
import {
  useAddWasteFormulaMutation,
  useUpdateWasteFormulaMutation,
} from "src/api/inventory/waste-formulas";

interface IModel {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch?: Function;
}

const schema = yup.object().shape({
  items: yup
    .array()
    .of(
      yup.object().shape({
        item_id: yup.string().required("required"),
        item_name: yup.string(),
        item_sku: yup.string(),
        item_unit: yup.string(),
        formulas: yup.array().of(
          yup.object().shape({
            name: yup.string().required("required"),
            percentage: yup
              .number()
              .typeError("Required")
              .required("required")
              .moreThan(0, "Must be More than 0")
              .max(100, "Percentage cannot exceed 100"),
          })
        ),
      })
    )
    .required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const AddEditWasteFormula = (props: IModel) => {
  const { open, handleClose, row, refetch = () => {} } = props;
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const mode = !row ? "add" : "edit";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  // APIs
  const { data: itemsData } = useGetItemsList();
  const { mutate, status, error, isPending } = useAddWasteFormulaMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateWasteFormulaMutation();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { fields, append, remove, update } = useFieldArray({
    name: "items",
    control,
  });

  const appendFormula = (itemIndex: number) => {
    if (getValues(`items.${itemIndex}.formulas`)?.length === 2) {
      return;
    }
    const formulasField = getValues(`items.${itemIndex}.formulas`);
    const newFormulas = [...(formulasField || []), { name: "", percentage: 0 }];

    // setValue(`items.${itemIndex}.formulas`, newFormulas);
    update(itemIndex, {
      ...getValues(`items.${itemIndex}`),
      formulas: newFormulas,
    });
  };

  const removeFormula = (itemIndex: number, formulaIndex: number) => {
    const formulasField = getValues(`items.${itemIndex}.formulas`);
    const newFormulas = formulasField?.filter(
      (_, index) => index !== formulaIndex
    );
    update(itemIndex, {
      ...getValues(`items.${itemIndex}`),
      formulas: newFormulas,
    });
  };

  // Function to transform the data
  const transformData = (data: any) => {
    return {
      items: data?.map((item: any) => {
        // Map each formula to a field name and percentage
        const transformedItem = {
          item_id: +item.item_id,
          ...item.formulas.reduce((acc: any, formula: any, index: number) => {
            acc[`field_${index + 1}_name`] = formula.name;
            acc[`field_${index + 1}_percentage`] = formula.percentage;
            return acc;
          }, {}),
        };

        return transformedItem;
      }),
    };
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const transItems = transformData(data.items);
    console.log({ data, transItems });

    if (mode === "add") {
      mutate(transItems);
    } else {
      mutateUpdate({ id: row?.id, ...transItems.items[0] });
    }
  };

  console.log({ errors });

  useEffect(() => {
    if (!!row) {
      const formulas = [
        {
          name: row.field_1_name,
          percentage: row.field_1_percentage,
        },
      ];

      if (row?.field_2_percentage) {
        formulas.push({
          name: row.field_2_name,
          percentage: row.field_2_percentage,
        });
      }

      reset({
        items: [
          {
            item_id: row.item?.id,
            item_name: row.item?.name,
            item_sku: row.item?.sku,
            item_unit: row.item?.unit?.name,
            formulas: formulas,
          },
        ],
      });
    }
  }, [row]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  const selectedItemsIds = watch("items")?.map((item) => item.item_id);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={3}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: 657 },
            bgcolor: "#FFF",
            border: "1px solid #D0D5DD",
            borderRadius: 1,
            boxShadow: 24,
            p: 3,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={0}
          >
            <Typography color={"#101828"} fontSize={"20px"}>
              {mode === "add" ? t("addWasteFormula") : t("editWasteFormula")}
            </Typography>
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {fields.map((itemField, index) => {
            console.log({ itemField });
            return (
              <Stack
                key={itemField.id}
                direction={{ xs: "column", md: "row" }}
                padding={"8px 16px"}
                borderRadius={1.5}
                border={"1px solid #F0F0F0"}
                spacing={"21px"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={{ xs: "70%", sm: "40%" }}
                >
                  <Box>
                    <Typography
                      color={"#667085"}
                      fontSize={"14px"}
                      lineHeight={"24px"}
                    >
                      {t("item")}
                    </Typography>
                    <Typography
                      color={"#667085"}
                      fontSize={"14px"}
                      lineHeight={"24px"}
                      fontWeight={700}
                    >
                      {itemField.item_name}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      color={"#667085"}
                      fontSize={"14px"}
                      lineHeight={"24px"}
                    >
                      {t("sku")}
                    </Typography>
                    <Typography
                      color={"#667085"}
                      fontSize={"14px"}
                      lineHeight={"24px"}
                      fontWeight={700}
                    >
                      {itemField.item_sku}
                    </Typography>
                  </Box>
                </Box>

                <Stack spacing={2}>
                  {itemField.formulas?.map((formula, formulaIndex) => {
                    return (
                      <Stack
                        key={`${itemField.id} ${formulaIndex}`}
                        direction={"row"}
                        spacing={"11px"}
                        alignItems={"center"}
                      >
                        <Controller
                          name={`items.${index}.formulas.${formulaIndex}.name`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label={t("wasteName")}
                              variant="outlined"
                              {...field}
                              error={
                                !!errors.items?.[index]?.formulas?.[
                                  formulaIndex
                                ]?.name
                              }
                              helperText={
                                errors.items?.[index]?.formulas?.[formulaIndex]
                                  ?.name?.message
                              }
                              fullWidth
                              size="small"
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                            />
                          )}
                        />
                        <Typography
                          fontSize={"13px"}
                          lineHeight={"24px"}
                          color={"#667085"}
                        >
                          {itemField.item_unit}
                        </Typography>
                        <Controller
                          name={`items.${index}.formulas.${formulaIndex}.percentage`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              type="number"
                              label={"Tirm&Cut"}
                              variant="outlined"
                              {...field}
                              error={
                                !!errors.items?.[index]?.formulas?.[
                                  formulaIndex
                                ]?.percentage
                              }
                              helperText={
                                errors.items?.[index]?.formulas?.[formulaIndex]
                                  ?.percentage?.message
                              }
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                        <Typography
                          fontSize={"13px"}
                          lineHeight={"24px"}
                          color={"#667085"}
                          marginRight={"11px"}
                        >
                          %
                        </Typography>
                        {itemField.formulas &&
                          itemField.formulas.length > 1 && (
                            <IconButton
                              onClick={() => removeFormula(index, formulaIndex)}
                            >
                              <img src={DeleteIcon} alt="" width={"16px"} />
                            </IconButton>
                          )}
                      </Stack>
                    );
                  })}

                  <Button
                    variant="text"
                    size="small"
                    startIcon={<img src={AddMoreIcon} alt="" />}
                    sx={{ alignSelf: "flex-start" }}
                    onClick={() => appendFormula(index)}
                  >
                    {t("addMore")}
                  </Button>
                </Stack>
              </Stack>
            );
          })}

          {mode === "add" ? (
            <Button
              variant="outlined"
              color="tertiary"
              size="small"
              startIcon={<img src={AddMoreTertiaryIcon} alt="" />}
              sx={{
                alignSelf: "flex-start",
                padding: "8px 12px",
                borderRadius: "8px",
              }}
              onClick={handleOpenMenu}
            >
              {t("searchAndAddItem")}
            </Button>
          ) : null}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {itemsData?.data
              .filter((item: any) => !item.formula)
              .filter((item: any) => !selectedItemsIds?.includes(item.id))
              .map((item: any) => (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    append({
                      item_id: item.id,
                      item_name: item.name,
                      item_sku: item.sku,
                      item_unit: item.unit?.name,
                      formulas: [
                        {
                          name: "",
                          percentage: 1,
                        },
                      ],
                    });
                    handleCloseMenu();
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Menu>

          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? generalT("submit") : generalT("save")}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};

export default AddEditWasteFormula;
