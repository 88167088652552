import { Box, Grid, Stack } from "@mui/material";
import ProfitChart from "./profit-chart";
import Filters from "./filters";
import MoneyOwnChart from "./money-own-chart";
import InventoryChart from "./inventory-chart";
import PurchaseCharts from "./purchase-chart";
import {
  useGetMoneyOwnChartData,
  useGetProfitChartData,
  useGetTotalInventoryChartData,
  useGetTotalPurchaseChartData,
} from "src/api/dashboard";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as animationData from "src/assets/animated/loader.json";
import moment from "moment";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Dashboard = () => {
  const [branches, setBranches] = useState<number[]>([]);
  const [payments, setPayments] = useState<number[]>([]);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("month").format("yyyy-MM-DD"),
  });

  const { data, refetch, isFetching } = useGetProfitChartData({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 && { branches: branches }),
    ...(payments.length > 0 && { payment_methods: payments }),
  });

  const {
    data: moneyOwnData,
    refetch: refetchMoneyOwn,
    isFetching: isFetchingMoneyOwn,
  } = useGetMoneyOwnChartData({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 && { branches: branches }),
    // ...(payments.length > 0 && { payment_methods: payments }),
  });

  const {
    data: purchaseData,
    refetch: refetchPurchase,
    isFetching: isFetchingPurchase,
  } = useGetTotalPurchaseChartData({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 && { branches: branches }),
  });

  const {
    data: inventoryData,
    refetch: refetchInventory,
    isFetching: isFetchingInventory,
  } = useGetTotalInventoryChartData({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(branches.length > 0 && { branches: branches }),
  });

  useEffect(() => {
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = dateRange.endDate ? moment(dateRange.endDate) : null;
    if (startDate && endDate && endDate.diff(startDate, "days") < 31) {
      refetch();
      refetchMoneyOwn();
      refetchPurchase();
      refetchInventory();
    }
  }, [branches, dateRange.endDate, dateRange.startDate]);

  useEffect(() => {
    refetch();
  }, [payments]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Filters
        branches={branches}
        setBranches={setBranches}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      {isFetching ? (
        <Box display={"flex"} alignItems={"center"} height={"60vh"}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </Box>
      ) : (
        <>
          <ProfitChart
            data={data?.data}
            payments={payments}
            setPayments={setPayments}
          />
          <MoneyOwnChart data={moneyOwnData?.data} />
          <Grid container spacing={"16px"} columns={100}>
            <Grid item xs={100} md={50} mb={{ xs: "20px" }}>
              <InventoryChart data={inventoryData?.data} />
            </Grid>
            <Grid item xs={100} md={50}>
              <PurchaseCharts data={purchaseData?.data} />
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};

export default Dashboard;
