import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import {
  useAddOCAccountMutation,
  useUpdateOCAccountMutation,
} from "src/api/operation-cost/accounts";
import { useTranslation } from "react-i18next";

interface IProps {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch: Function;
}

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  type: yup
    .string()
    .required("Required")
    .oneOf(["fixed", "variable", "asset"] as const),
  start_balance: yup.string().required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const AddEditAccountPopup = (props: IProps) => {
  const { open, handleClose, row, refetch = () => {} } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [mode, setMode] = useState<"add" | "edit">(!row ? "add" : "edit");
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  // APIS
  const { mutate, status, error, isPending } = useAddOCAccountMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateOCAccountMutation();

  const { control, handleSubmit, formState, reset } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (mode === "add") {
      mutate(data);
    } else {
      mutateUpdate({
        id: row.id,
        ...data,
      });
    }
  };

  useEffect(() => {
    if (!!row) {
      reset({
        name: row.name,
        type: row.type,
        start_balance: row.start_balance,
      });
    }
  }, [row]);

  //Add OC account success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update OC account success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {mode === "add" ? t("addNewAccount") : t("editAccount")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  label={generalT("accountName")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!formState.errors.name}
                  helperText={formState.errors.name?.message}
                  disabled={row?.has_invoices}
                />
              )}
            />
            <FormControl
              fullWidth
              size="small"
              error={!!formState.errors.type}
              disabled={mode === "edit"}
            >
              <InputLabel id="demo-simple-select-label">
                {generalT("accountType")}
              </InputLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("accountType")}
                    disabled={mode === "edit"}
                  >
                    <MenuItem value={"fixed"}>{generalT("fixed")}</MenuItem>
                    <MenuItem value={"variable"}>{t("variable")}</MenuItem>
                    <MenuItem value={"asset"}>{t("asset")}</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {formState.errors.type?.message}
              </FormHelperText>
            </FormControl>
            <Controller
              name="start_balance"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  label={generalT("startBalance")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!formState.errors.start_balance}
                  helperText={formState.errors.start_balance?.message}
                />
              )}
            />
          </Stack>
          <Stack mt={3} spacing={2} direction={"row"}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              loading={isPending || isPendingUpdate}
              type="submit"
              variant="contained"
              fullWidth
            >
              {generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditAccountPopup;
