import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IAggCostChartDayData } from "src/types/aggregators";
const colors = [
  "#344054",
  "#4D4DEC",
  "#667085",
  "#F04438",
  "#FF8B3D",
  "#73AF00",
];
const Chart = ({ data }: { data: IAggCostChartDayData[] | undefined }) => {
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation("aggregators");

  const daysArr: any = [];
  const aggFeesArr: any = [];
  const aggDeliveryCostArr: any = [];
  const aggBankFeeArr: any = [];
  const compensationArr: any = [];
  const totalCostArr: any = [];
  const otherFeesArr: any = [];

  data?.forEach((item) => {
    daysArr.push(moment(item.day).format(matchesMediumUp ? "D/M" : "DD / MM"));
    aggFeesArr.push(item.data.aggregator_fee);
    aggDeliveryCostArr.push(item.data.delivery_cost);
    aggBankFeeArr.push(item.data.bank_fee);
    compensationArr.push(item.data.compensation);
    otherFeesArr.push(item.data.other_fees);
    totalCostArr.push(item.data.total_aggregator_cost);
  });

  const chartData = {
    series: [
      {
        name: t("aggFee"),
        data: aggFeesArr,
      },
      {
        name: t("deliveryCost"),
        data: aggDeliveryCostArr,
      },
      {
        name: t("aggBankFee"),
        data: aggBankFeeArr,
      },
      {
        name: t("compensation"),
        data: compensationArr,
      },
      {
        name: t("otherFees"),
        data: otherFeesArr,
      },
      {
        name: t("totalAggCost"),
        data: totalCostArr,
      },
    ],
    options: {
      chart: {
        id: "line-chart",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      colors: colors,

      xaxis: {
        categories: daysArr,
      },
      markers: {
        size: 4, // Adjust the size of the marker
        colors: colors,
      },
      stroke: {
        width: 2,
      },
      legend: {
        show: true,
        fontSize: "15px",
        markers: {
          width: 15,
          height: 15,
          strokeWidth: 0,
          radius: 2,
        },
        itemMargin: {
          horizontal: 0,
          vertical: matchesMediumUp ? 20 : 0,
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={350}
      lineWidth={10}
    />
  );
};

export default Chart;
