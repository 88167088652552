import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  branch_id: number;
}

export const useGetLevelsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-inventory-levels-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/levels`, { params });
    },
    gcTime: 0,
    enabled: false
  });
