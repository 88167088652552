import React, { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  TextField,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import saudiIcon from "src/assets/svg-icons/flags/saudi.svg";
import { useGetDraftCompanyId, useSignupMutation } from "src/api/auth";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const Form: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("auth");
  const { t: T } = useTranslation("general");

  // APIS
  const { mutate, status, isPending, error } = useSignupMutation();
  const { data: draftCompanyData } = useGetDraftCompanyId();

  // React hook form
  const schema = yup.object().shape({
    name: yup
      .string()
      // .matches(/^[a-zA-Z\s]*$/, T("invalidName")) // Allows only letters and spaces
      .required(T("required")),
    email: yup.string().email(T("invalidEmail")).required(T("required")),
    password: yup
      .string()
      .required(T("required"))
      .min(8, T("password8characters")),
    phone: yup
      .string()
      .required(T("required"))
      .matches(/^(05\d{8}|5\d{8})$/, T("invalidSaudiNumber")),
  });

  interface IFormInputs extends yup.InferType<typeof schema> {}

  const { control, handleSubmit, formState } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    mutate({
      ...data,
      phone: `+966${data.phone}`,
      reference_number: Number(draftCompanyData?.data.reference_number),
    });
  };

  useEffect(() => {
    if (status === "success") {
      navigate("/login");
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("signedUpSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems={"flex-start"} sx={{ width: "100%" }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("fullName")}
              size="small"
              {...field}
              error={!!formState.errors.name}
              helperText={formState.errors.name?.message}
              fullWidth
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              label={t("email")}
              {...field}
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
              fullWidth
            />
          )}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              label={t("phoneNumber")}
              type="number"
              {...field}
              error={!!formState.errors.phone}
              helperText={formState.errors.phone?.message}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Stack direction={"row"} gap={1}>
                      <img src={saudiIcon} alt="" />
                      <Typography>+966</Typography>
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Stack spacing={"10px"} width="100%">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl
                {...field}
                variant="outlined"
                error={!!formState.errors.password}
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {t("password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  aria-describedby="my-helper-text"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? (
                          <VisibilityOffOutlined fontSize="small" />
                        ) : (
                          <VisibilityOutlined fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("password")}
                />
                <FormHelperText id="my-helper-text">
                  {formState.errors.password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <Typography color="#667085" fontSize="14px">
            {t("useCharactersMix")}.
          </Typography>
        </Stack>

        <Typography>
          {t("companyId")}:{" "}
          <strong>{draftCompanyData?.data.reference_number}</strong>{" "}
          <Typography component={"span"} color={"info"}>
            ({t("saveToUseForLogin")})
          </Typography>
        </Typography>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          loading={isPending}
        >
          {t("signUp")}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Form;
