import { Box, Grid, Stack, Typography } from "@mui/material";
import sealIcon from "src/assets/svg-icons/info.svg";
import TotalSalesLineIcon from "src/assets/svg-icons/aggregators/total-sales-line.svg";
import ListItem, { IListItemProps } from "src/shared/components/list-item";
import CustomList from "src/shared/components/list";
import { ITotalSalesData } from "src/types/aggregators";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const TotalSales = ({ data }: { data: ITotalSalesData | undefined }) => {
  const { t } = useTranslation("aggregators");
  const listData: IListItemProps[] = [
    {
      label: t("cogs"),
      value: `${formatNumber(Number(data?.cogs))}`,
      icon: "show",
      iconColor: "black",
    },
    {
      label: t("aggFees"),
      value: `${formatNumber(Number(data?.aggregator_fee))}`,
    },
    {
      label: t("aggDeliveryCost"),
      value: `${formatNumber(Number(data?.aggregator_delivery_cost))}`,
    },
    {
      label: t("aggBankFee"),
      value: `${formatNumber(Number(data?.aggregator_bank_fee))}`,
    },
    {
      label: t("compensation"),
      value: `${formatNumber(Number(data?.compensation))}`,
    },
    {
      label: t("otherFees"),
      value: `${formatNumber(Number(data?.other_fee))}`,
    },
    {
      label: t("totalCosts"),
      value: `${formatNumber(Number(data?.total_costs))}`,
      divider: "hidden",
    },
  ];

  return (
    <Stack spacing={3} p={"18px"} border={"1px solid #EAECF0"} borderRadius={1}>
      <Grid container gap={"12px"}>
        <Grid
          item
          xs={12}
          lg={6.8}
          p={"12px"}
          bgcolor={"#F9FAFB"}
          border={"1px solid #EAECF0"}
          display={"flex"}
          justifyContent={"space-between"}
          borderRadius={1}
        >
          <Box>
            <Stack gap={"10px"} direction={"row"} mb={1}>
              <Typography color={"#667085"} lineHeight={"19.2px"}>
                {t("netSalesWithoutTax")}
              </Typography>
              <img src={sealIcon} alt="" width={"16px"} />
            </Stack>
            <Typography
              fontSize={"23px"}
              lineHeight={"27.6px"}
              fontWeight={600}
              color={"#101828"}
            >
              {t("sar")} {formatNumber(data?.net_sales as number)}
            </Typography>
          </Box>
          <img src={TotalSalesLineIcon} alt="" />
        </Grid>
        <Grid
          p={"12px"}
          bgcolor={"#F9FAFB"}
          border={"1px solid #EAECF0"}
          xs={12}
          lg={4.8}
          borderRadius={1}
        >
          <Typography mb={1} lineHeight={"19.2px"}>
            {t("orderQuantity")}
          </Typography>
          <Typography
            color={"#101828"}
            fontSize={"23px"}
            lineHeight={"27.6px"}
            fontWeight={600}
          >
            {formatNumber(Number(data?.order_quantity.toFixed(2)))}
          </Typography>
        </Grid>
      </Grid>
      {/* list */}
      <CustomList listData={listData} />
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography color={"#1D2939"} lineHeight={"19.2px"}>
          {" "}
          {t("profit")}
        </Typography>
        <Stack direction={"row"} gap={"10px"}>
          <Typography color={"#1D2939"} lineHeight={"19.2px"} fontWeight={700}>
            ({data?.profit_percentage}%)
          </Typography>
          <Typography color={"#1D2939"} lineHeight={"19.2px"}>
            {formatNumber(data?.profit as number)}
          </Typography>
        </Stack>
      </Box>
      <Stack
        bgcolor={"#FCFCFD"}
        borderRadius={1}
        p={"12px"}
        border={"1px solid #EAECF0"}
        spacing={1}
      >
        <ListItem
          label={t("salesTax")}
          value={`${formatNumber(data?.sales_tax as number)}`}
          icon="show"
          iconColor="black"
        />
        <ListItem
          label={t("refundTax")}
          value={`${formatNumber(data?.refund_tax as number)}`}
          icon="show"
          iconColor="black"
        />
        <ListItem
          label={t("totalOwn")}
          value={`${formatNumber(data?.total_own as number)}`}
          icon="show"
          divider="hidden"
          iconColor="black"
        />
      </Stack>
    </Stack>
  );
};

export default TotalSales;
