import { Box, Button, Stack, Typography } from "@mui/material";
import OperationIcon from "src/assets/svg-icons/side-bar/operation.svg";
import youtubeIcon from "src/assets/svg-icons/aggregators/youtube.svg";
import OperationCostTabs from "./tabs";
import { useTranslation } from "react-i18next";

const OperationCost = () => {
  const { t  } = useTranslation("operationCost");
  
  return (
    <Stack gap={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Box display={"flex"} justifyContent={"space-between"} gap={2} flexWrap={"wrap"}>
        <Stack
          direction={"row"}
          spacing={"12px"}
          alignItems={"center"}
          color={"#101828"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"60px"}
            height={"60px"}
            bgcolor={"#D0D5DD"}
            borderRadius={"16px"}
          >
            <img src={OperationIcon} width={"32px"} alt="" />
          </Box>
          <Box>
            <Typography
              fontSize={"32px"}
              lineHeight={"38.4px"}
              color={"#101828"}
            >
              {t("operationCost")}
            </Typography>
            <Typography fontSize={"20px"} lineHeight={"24px"} color={"#98A2B3"}>
             {t("listDownOperationCost")}
            </Typography>
          </Box>
        </Stack>

        <Button
          variant="outlined"
          color="error"
          sx={{ height: "48px", border: "1px solid  #B32318" }}
          startIcon={<img src={youtubeIcon} alt="" width={"34px"} />}
          href="https://www.youtube.com/watch?v=uxQmpaBLMpc"
          target="_blank"
        >
          {t("viewHelp")}
        </Button>
      </Box>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <OperationCostTabs />
    </Stack>
  );
};

export default OperationCost;
