import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "src/assets/svg-icons/logo.svg";

const WhatJazrSystem = () => {
  const { t } = useTranslation("home");

  return (
    <Grid
      container
      bgcolor={"#F5F5F5"}
      p={{ xs: "16px 16px 60px 16px", lg: "250px 100px 244px 200px" }}
      spacing={2}
    >
      <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"}>
        <Typography
          color={"#000"}
          fontWeight={{ xs: 700, md: 800 }}
          lineHeight={{ xs: "35px", md: "48px" }}
          mb={"15px"}
          fontSize={{ xs: "24px", md: "32px" }}
          sx={{ textAlign: { xs: "center", md: "start" } }}
        >
          {t("whatJazrSystem")}
        </Typography>
        <Typography
          color={"#333"}
          fontSize={{ xs: "16px", md: "20px" }}
          fontWeight={500}
          lineHeight={{ xs: "30px", lg: "34px" }}
          maxWidth={{ xs: "100%", md: "80%", lg: "580px" }}
          sx={{ textAlign: { xs: "center", md: "start" } }}
        >
          {t("JazrSystemInnovativeWay")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          width={{ xs: "30%", md: "60%", lg: "361px" }}
          height={{ xs: "100%", lg: "163px" }}
        >
          <img src={logo} alt="" width={"100%"} height={"100%"} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhatJazrSystem;
