import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RoleTable from "./table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Roles = () => {
  const navigate = useNavigate();
  const { t  } = useTranslation("settings");
  const handleNavigate = () => {
    navigate("/settings/roles/add-new-role");
  };
  return (
    <Stack spacing={3}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
         {t("rolesList")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNavigate}
        >
          {t("addNewRole")}
        </Button>
      </Stack>
      <RoleTable />
    </Stack>
  );
};

export default Roles;
