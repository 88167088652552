import { Stack } from "@mui/material";
import ItemsTabs from "./tabs";

const Items = () => {
  return (
    <Stack maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
       <ItemsTabs /> 
    </Stack>
  );
};

export default Items;
