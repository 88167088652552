import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
}

export const useGetOCAccountsList = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-operation-cost-accounts-list", params?.page],
    queryFn: async () => {
      return apiClient.get(`/api/accounts`, { params: params || {} });
    },
    gcTime: 0,
  });

interface IAccountBody {
  id?: string;
  name?: string;
  type?: "fixed" | "variable" | "asset";
  status?: boolean;
}

export const useAddOCAccountMutation = () =>
  useMutation<void, APIErrorResponse, IAccountBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/accounts`, data);
    },
  });

export const useUpdateOCAccountMutation = () =>
  useMutation<void, APIErrorResponse, IAccountBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/accounts/${id}`, rest);
    },
  });

export const useDeleteOCAccountMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/accounts/${id}`);
    },
  });