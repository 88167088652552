import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import sealIcon from "src/assets/svg-icons/info.svg";
import CustomList from "src/shared/components/list";
import { IListItemProps } from "src/shared/components/list-item";
import { IProductStatistic } from "src/types/aggregators";
import { formatNumber } from "src/utils";

interface ProductItemProps {
  img: string;
  title: string;
  data: IProductStatistic[] | undefined;
}

const ProductItem = (props: ProductItemProps) => {
  const { t } = useTranslation("aggregators");
  const { img, title, data } = props;
  const [productListItems, setProductListItems] = useState<IListItemProps[]>(
    []
  );
  useEffect(() => {
    if (productListItems.length === 0) {
      const products: IListItemProps[] = [];
      data?.forEach((item) =>
        products.push({
          label: item.name,
          value: `${formatNumber(item.percentage)}`,
        })
      );
      setProductListItems(products);
    }
  }, [data]);

  return (
    <Box borderRadius={"8px"} border={"1px solid #EAECF0"} p={3}>
      <img src={img} alt="" />
      <Stack direction={"row"} spacing={"3px"} mb={2}>
        <Typography fontSize={"14px"} lineHeight={"16.8px"} color={"#98A2B3"}>
          {title}
        </Typography>
        <img src={sealIcon} alt="" width={"16px"} />
      </Stack>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          fontSize={"14px"}
          fontWeight={600}
          color={"#1D2939"}
          lineHeight={"16.8px"}
        >
          {t("product")}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={600}
          color={"#1D2939"}
          lineHeight={"16.8px"}
        >
          {t("total")}
        </Typography>
      </Box>
      <Box width="100%" bgcolor="#EAECF0" height="1px" my={1} />
      {/* list item */}
      <CustomList listData={productListItems} />
    </Box>
  );
};
export default ProductItem;
