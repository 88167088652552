import { Grid, Typography } from "@mui/material";
interface IProps {
  title: string;
  text?: string;
  label: string;
  value: string;
}
const InfoItem = (props: IProps) => {
  const { title, text, label, value } = props;
  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Typography
          fontSize={"14px"}
          color={"#344054"}
          fontWeight={700}
          lineHeight={"18.8px"}
        >
          {title}:
          <Typography
            component={"span"}
            fontWeight={400}
            fontSize={"14px"}
            lineHeight={"18.8px"}
          >
            {" "}
            {text && `${text.substring(0, 30)}`}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        {label && (
          <Typography
            fontSize={"14px"}
            color={"#344054"}
            fontWeight={700}
            lineHeight={"18.8px"}
          >
            {label}:
            <Typography
              component={"span"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"18.8px"}
            >
              {" "}
              {value}
            </Typography>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default InfoItem;
