import { Box } from "@mui/material";
import Header from "./header";
import WhyJazrSystem from "./why-jazar-system";
import PublicMobilAppBar from "src/shared/components/public-mobile-appbar";
import WhatJazrSystem from "./what-jazar-system";
import JazarSystemPros from "./jazar-system-pros";
import JazarSystemDifference from "./jazar-system-difference";
import WhatUserNeed from "./what-user-need";
import Footer from "src/shared/components/footer";
import PublicAppBar from "src/shared/components/public-appbar";

const HomePage = () => {
  return (
    <>
      <Box display={{ xs: "none", md: "block" }}>
        <PublicAppBar />
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <PublicMobilAppBar />
      </Box>
      <Header />
      <WhatJazrSystem />
      <JazarSystemPros />
      <JazarSystemDifference />
      <WhatUserNeed />
      <WhyJazrSystem />
      <Footer />
    </>
  );
};

export default HomePage;
