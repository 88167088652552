import { Box, Stack, Typography } from "@mui/material";
import sealIcon from "src/assets/svg-icons/info.svg";
import blackSealIcon from "src/assets/svg-icons/black_info.svg";

export interface IListItemProps {
  label: string;
  value: string;
  divider?: "hidden" | "show";
  icon?: "show" | "hidden";
  iconColor?: "black" | "gray";
}
const CustomListListItem = (props: IListItemProps) => {
  const { label, value, divider, icon, iconColor } = props;

  return (
    <Box>
      <Box justifyContent={"space-between"} display={"flex"} mb={1}>
        <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
          <Typography fontSize={"14px"} color={"#1D2939"} lineHeight={"16.8px"}>
            {label}
          </Typography>

          {icon !== "show" ? (
            ""
          ) : iconColor !== "black" ? (
            <img src={sealIcon} alt="" width={"16px"} />
          ) : (
            <img src={blackSealIcon} alt="" width={"16px"} />
          )}
        </Stack>
        <Typography fontSize={"14px"} color={"#1D2939"} lineHeight={"16.8px"}>
          {value}
        </Typography>
      </Box>
      {divider !== "hidden" && (
        <Box width="100%" bgcolor="#EAECF0" height="1px" />
      )}
    </Box>
  );
};
export default CustomListListItem;
