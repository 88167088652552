import { ReactNode } from "react";
import { Box, Radio, Stack, Typography } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IOption {
  label: string;
  value: string;
  icon: ReactNode;
  disabled?: Boolean;
}

interface IProps {
  label: string;
  options: IOption[];
  value: string;
  onChange?: (value: string) => void;
}

const BoxSelect = (props: IProps) => {
  const { label, options, value, onChange = () => {} } = props;
  return (
    <Stack gap={1.5} width={"100%"}>
      <Typography>{label}</Typography>
      <Stack gap={"10px"} direction={{ xs: "column", sm: "row" }}>
        {options.map((option) => {
          const { label, icon, value: optionValue , disabled = false} = option;
          return (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              border={
                optionValue === value
                  ? "1px solid #D0D5DD"
                  : "1px solid #F2F4F7"
              }
              padding={2}
              borderRadius={"8px"}
              bgcolor={optionValue === value ? "#FFE6D4" : "#FCFCFD"}
              width={"100%"}
              sx={{ cursor: "pointer",pointerEvents: disabled ? "none" : "unset" }}
              onClick={() => onChange(optionValue)}
            >
              <Stack flexDirection={"row"} gap={"10px"}>
                {icon}
                <Typography>{label}</Typography>
              </Stack>
              <Radio
                checked={optionValue === value}
                icon={<CircleOutlinedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default BoxSelect;
