import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "src/assets/svg-icons/logo.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setLocalStoredData } from "src/utils/_localStorage";
import { ConstLocalStorage } from "src/constants/_localStorage";
import englandIcon from "src/assets/svg-icons/flags/england.svg";
import saudiIcon from "src/assets/svg-icons/flags/saudi.svg";
import arrowDownIcon from "src/assets/svg-icons/home/down-arrow.svg";
import changeLanguageIcon from "src/assets/svg-icons/home/change-language.svg";

export const AppBarItems = [
  {
    label: "whyJazrSystem",
    path: "#whyJazrSystem",
  },
  {
    label: "products",
    path: "products",
  },

  {
    label: "prices",
    path: "/prices",
  },

  {
    label: "aboutUs",
    path: "aboutUs",
  },
  {
    label: "contactUs",
    path: "contactUs",
  },
];

const PublicAppBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("home");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language: "en" | "ar") => {
    i18n.changeLanguage(language);
    setLocalStoredData(ConstLocalStorage.language, language);
    setAnchorEl(null);
  };

  return (
    <Stack
      p={{ xs: 2, sm: "40px 108px 40px 108px" }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      bgcolor={"#FFF7F7"}
    >
      <Stack spacing={"37px"} direction={"row"} alignItems={"center"}>
        <img src={logo} alt="" style={{ marginTop: "-15px" }} />
        <Stack spacing={2} direction={"row"}>
          {AppBarItems.map((item, index) => (
            <Box key={index}>
              {index === 2 ? (
                <Link
                  key={index}
                  to={item.path}
                  sx={{ color: "#344054", textDecoration: "none" }}
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"24px"}
                  component={RouterLink}
                >
                  {t(item.label)}
                  <Typography
                    component={"span"}
                    fontSize={"16px"}
                    color={"#65696E"}
                    lineHeight={"24px"}
                  >
                    {t("freeTrial")}
                  </Typography>
                </Link>
              ) : (
                <Link
                  to={item.path}
                  sx={{ color: "#344054", textDecoration: "none" }}
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"24px"}
                  component={RouterLink}
                >
                  {t(item.label)}
                </Link>
              )}
            </Box>
          ))}
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Button variant="text" onClick={handleClick} size="small">
          <Stack direction={"row"} spacing={1}>
            <img src={arrowDownIcon} alt="" />
            <Typography
              fontSize={"14px"}
              fontWeight={500}
              color={"#334155"}
              lineHeight={"14px"}
            >
              {i18n.language === "en" ? "En" : "Ar"}
            </Typography>
            <img src={changeLanguageIcon} alt="" />
          </Stack>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleLanguageChange("en")} sx={{ gap: 1 }}>
            <img src={englandIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              English
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleLanguageChange("ar")} sx={{ gap: 1 }}>
            <img src={saudiIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              العربية
            </Typography>
          </MenuItem>
        </Menu>
        <Button variant="outlined" color="tertiary" onClick={() => navigate("/sign-up")}>
          {t("signUp")}
        </Button>
        <Button variant="contained" onClick={() => navigate("/login")}> {t("signIn")}</Button>
      </Stack>
    </Stack>
  );
};

export default PublicAppBar;
