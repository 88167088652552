import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetBranches } from "src/api/generic";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IModel {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch?: Function;
}

interface FormInputs {
  branch_id: number;
}

const schema = yup.object().shape({
  branch_id: yup.number().required("required"),
});

const SelectCountBranch = (props: IModel) => {
  const { open, handleClose, row, refetch = () => {} } = props;
  const navigate = useNavigate();
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");

  // APIS
  const { data: branchesData } = useGetBranches();

  const { control, handleSubmit, formState, reset } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const selectedBranch = branchesData?.data.find(
      (branch) => branch.id === data.branch_id
    );

    navigate(`/${data.branch_id}/${selectedBranch?.name}/add-stocktaking`);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("addStocktaking")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <FormControl
              fullWidth
              size="small"
              error={!!formState.errors.branch_id}
            >
              <InputLabel id="demo-simple-select-label">
                {generalT("branch")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("branch")}
                  >
                    {branchesData?.data?.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={`${id}`}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {generalT(`${formState.errors.branch_id?.message || ""}`)}
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack mt={3} spacing={2} direction={"row"}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              fullWidth
            >
              {generalT("submit")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default SelectCountBranch;
