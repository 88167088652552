import { Stack, Typography } from "@mui/material";
import InfoIcon from "src/assets/svg-icons/info.svg";

interface IHeadTextProps {
  text: string;
}
const TableHeadText = (props: IHeadTextProps) => {
  const { text } = props;
  return (
    <Stack direction={"row"} spacing={0.5} justifyContent={"center"}>
      <Typography
        fontSize={"12px"}
        color={"#475467"}
        lineHeight={"18px"}
        fontWeight={600}
      >
        {text}
      </Typography>

      <img src={InfoIcon} alt="" />
    </Stack>
  );
};
export default TableHeadText;
