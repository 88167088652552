import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCapitalList } from "src/api/settings/capital";
import AddEditContributorPopup from "../add-edit-contributor";
import AddIcon from "@mui/icons-material/Add";
import { formatNumber } from "src/utils";

const CapitalTable = () => {
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");
  const tableHeadText = [
    generalT("reference"),
    t("contributorName"),
    t("paidCapital"),
    t("receivedDate"),
    t("profitDistribution"),
    t("note"),
    generalT("createdBy"),
    generalT("createdAt"),
    generalT("actions"),
  ];

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data, isLoading, refetch } = useGetCapitalList({
    page: pageNumber + 1,
  });

  const [total, setTotal] = useState<number>(0);

  const isEmptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Box>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("capital")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          {t("addContributor")}
        </Button>
      </Stack>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={9} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">
                    {generalT("sar")} {formatNumber(row.amount)}
                  </TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.profit}</TableCell>
                  <TableCell align="center">{row.note}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center" dir="ltr">
                    {row.created_at}
                  </TableCell>
                  <TableCell align="center">
                    <TableActions
                      rowId={row.id}
                      refetch={refetch}
                      capitalData={row}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {isEmptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {open && (
        <AddEditContributorPopup
          open={open}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Box>
  );
};
export default CapitalTable;
