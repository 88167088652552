import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import sealIcon from "src/assets/svg-icons/info.svg";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { formatLongNumbers, formatNumber } from "src/utils";
import { IAccountsTypesChartData } from "src/types/operation-cost";
import { useTranslation } from "react-i18next";

const colors = ["#F79009", "#667085"];

const AccountsChart = ({
  data,
}: {
  data: IAccountsTypesChartData | undefined;
}) => {
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const daysArr: any = [];
  const fixedArr: any = [];
  const variableArr: any = [];
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  data?.days.forEach((item: any) => {
    daysArr.push(moment(item.day).format(matchesMediumUp ? "D/M" : "DD / MM"));
    fixedArr.push(item.fixed);
    variableArr.push(item.variable);
  });

  const chartOptions: ApexCharts.ApexOptions = {
    noData: {
      text: generalT("noData"),
    },
    colors: colors,
    chart: {
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    legend: {
      show: true,
      labels: {
        // Custom legend names
        // names: ['Series A', 'Series B', 'Series C'],
      },
      // customLegendItems: [
      //   `${t("fixedCost")} (${formatNumber(data?.fixed as number)} ${generalT(
      //     "sar"
      //   )})`,
      //   `${t("variableCost")} (${formatNumber(
      //     data?.variable as number
      //   )} ${generalT("sar")})`,
      // ],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetY: 5,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        // columnWidth: "20px",
        columnWidth: matchesMediumUp  ? "20px" : '60%',
        dataLabels: {
          total: {
            enabled: false,
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value: number): string => value?.toString(),
      },
    },
    yaxis: {
      labels: {
        formatter: formatLongNumbers,
      },
    },
    xaxis: {
      categories: daysArr,
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false
    },
  };

  const chartSeries = [
    {
      name: `${t("fixedCost")}`,
      data: fixedArr,
    },
    {
      name: `${t("variableCost")}`,
      data: variableArr,
    },
  ];

  return (
    <Paper
      variant="outlined"
      sx={{
        boxShadow: " 0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
      }}
    >
      <Box px={3} pt={3} pb={0.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={"6px"}>
            <Typography
              fontSize={"16px"}
              lineHeight={"22px"}
              textTransform={"capitalize"}
              fontWeight={500}
            >
              {t("accountsStats")}
            </Typography>
            <img src={sealIcon} alt="" width={"16px"} />
          </Stack>
        </Box>
        <div id="chart">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={350}
          />
        </div>
      </Box>
    </Paper>
  );
};

export default AccountsChart;
