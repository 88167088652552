import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Equations from "./equations";
import LineChart from "./chart";
import { IProfitChartData } from "src/types/dashboard";
import { useGetPaymentMethods } from "src/api/generic";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const ProfitChart = ({
  data,
  payments,
  setPayments,
}: {
  data: IProfitChartData | undefined;
  payments: number[];
  setPayments: Function;
}) => {
  const [tempSelectedPayments, setTempSelectedPayments] = useState<number[]>(payments);
  const { data: paymentsData } = useGetPaymentMethods();
  const { t } = useTranslation("dashboard");

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setTempSelectedPayments(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleClose = () => {
    setPayments(tempSelectedPayments); // Set the final state
  };
  

  return (
    <Paper
      variant="outlined"
      sx={{
        boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
      }}
    >
      {data && (
        <>
          <Equations data={data} />
          <Box px={3} pt={3} pb={0.5}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontSize={"16px"}
                lineHeight={"22px"}
                textTransform={"capitalize"}
                fontWeight={500}
              >
                {t("profitChart")}
              </Typography>

              {/* Select Payments */}
              <Box sx={{ minWidth: 150, maxWidth: 150 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-multiple-checkbox-label">
                  {t("payments")}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={tempSelectedPayments}
                    onChange={handleChange}
                    onClose={handleClose}
                    input={<OutlinedInput label={t("payments")} />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (selectedId: number) =>
                            paymentsData?.data.find(
                              (branch: any) => branch.id === +selectedId
                            )?.name
                        )
                        .join(", ")
                    }
                  >
                    {paymentsData?.data.map((branch) => (
                      <MenuItem
                        key={branch.id}
                        value={branch.id}
                        sx={{ padding: "0" }}
                      >
                        <Checkbox
                          size="small"
                          checked={tempSelectedPayments.indexOf(branch.id) > -1}
                        />
                        <ListItemText primary={branch.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <LineChart data={data} />
            {/* <Control /> */}
          </Box>
        </>
      )}
    </Paper>
  );
};
export default ProfitChart;
