import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";
import {
  IAccountsCostsChartData,
  IAccountsTypesChartData,
} from "src/types/operation-cost";

interface IChartsParams {
  start_date: string;
  end_date: string;
}

export const useGetOCAccountTypesCosts = (params: IChartsParams) =>
  useQuery<any, APIErrorResponse, { data: IAccountsTypesChartData }>({
    queryKey: [
      "get-operation-cost-accounts-types-costs-chart",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/operationCosts/account-report`, { params });
    },
  });

export const useGetOCAllAccountCosts = (params: IChartsParams) =>
  useQuery<any, APIErrorResponse, { data: IAccountsCostsChartData }>({
    queryKey: [
      "get-operation-cost-all-accounts-costs-chart",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/operationCosts/cost-report`, { params });
    },
  });
