import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useDeleteAggMutation } from "src/api/aggreagators";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";

const TableActions = ({
  rowId,
  rowName,
  isDeletable,
  refetch,
}: {
  rowId: number;
  rowName: string;
  isDeletable: boolean;
  refetch: Function;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("aggregators");

  const { mutate, isPending, status, error } = useDeleteAggMutation();

  const navigateToEditPage = () => {
    navigate(`/aggregators/edit-agg/${rowId}`);
  };

  const handleClickOpen = () => {
    if (!isDeletable) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("canNotDeleteAggs"),
        },
      ]);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    mutate(rowId);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <IconButton onClick={navigateToEditPage}>
        <img src={EditIcon} alt="" />
      </IconButton>
      <IconButton onClick={handleClickOpen}>
        <img src={DeleteIcon} alt="" />
      </IconButton>

      <ConfirmPopup
        open={open}
        item={rowName}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
