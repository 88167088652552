import {
  Typography,
  Stack,
  FormControl,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";
import { FormData } from "../../constants";
import { useTranslation } from "react-i18next";

const AddAggregators = ({
  control,
  errors,
  watch,
  append,
  replaceAggregators,
}: {
  control: Control<FormData, any>;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  append: Function;
  replaceAggregators: any;
}) => {
  useEffect(() => {
    if (
      watch("has_aggregators") === "yes" &&
      watch("aggregators").length === 0
    ) {
      append({
        aggregator_name: "",
        aggregator_fee: "",
        payments: [],
        delivery_type: "order",
        delivery_cost: "0",
      });
    } else if (watch("has_aggregators") === "no") {
      replaceAggregators([]);
    }
  }, [watch("has_aggregators")]);
  const { t, i18n } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");
  return (
    <div>
      <Typography fontSize={"26px"} fontWeight={600}>
        {t("addYourAgg")}
      </Typography>
      <Typography mb={15} color={"textSecondary"}>{t("letKnowYou")}</Typography>
      <form>
        <Stack gap={2} alignItems={"flex-start"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={{ xs: 1, sm: 1, md: 3 }}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <Typography color={"textSecondary"}>
              {t("areYouDealWithAgg")}
              {i18n.language === "en" ? "?" : "؟"}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.has_aggregators}
              sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
            >
              <Controller
                name="has_aggregators"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={generalT("yes")}
                      sx={{ color: "#98A2B3" }}
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={generalT("no")}
                      sx={{ color: "#98A2B3" }}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
          <FormHelperText error id="my-helper-text" sx={{ mt: -3, px: 2 }}>
            {errors.has_aggregators?.message}
          </FormHelperText>
        </Stack>
      </form>
    </div>
  );
};

export default AddAggregators;
