import { Box, Button, Stack, Typography } from "@mui/material";
import SettingsTabs from "./tabs";
import settingIcon from "src/assets/svg-icons/side-bar/settings.svg";
import { useTranslation } from "react-i18next";
import youtubeIcon from "src/assets/svg-icons/aggregators/youtube.svg";

const Settings = () => {
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");

  return (
    <Stack gap={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        gap={2}
        flexWrap={"wrap"}
      >
        <Stack
          direction={"row"}
          spacing={"12px"}
          alignItems={"center"}
          color={"#101828"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"60px"}
            height={"60px"}
            bgcolor={"#D0D5DD"}
            borderRadius={"16px"}
          >
            <img src={settingIcon} width={"32px"} alt="" />
          </Box>
          <Typography fontSize={"32px"} lineHeight={"38.4px"}>
            {t("settings")}
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          color="error"
          sx={{ height: "48px", border: "1px solid  #B32318" }}
          startIcon={<img src={youtubeIcon} alt="" width={"34px"} />}
          href="https://www.youtube.com/watch?v=QESpD1Ypahs"
          target="_blank"
        >
          {generalT("viewHelp")}
        </Button>
      </Box>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <SettingsTabs />
    </Stack>
  );
};

export default Settings;
