import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dashboard from "src/assets/img/home/dashboard.png";
import Settings from "src/assets/img/home/settings.png";
import followerImg from "src/assets/svg-icons/home/follower.svg";

const JazarSystemPros = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <Stack
      bgcolor={"#FFF7F7"}
      p={{ xs: "16px 16px 60px 16px", lg: "70px 108px 163px 113px" }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        mb={{ xs: "24px", lg: "107px" }}
      >
        <Typography
          fontSize={{ xs: "24px", md: "32px" }}
          fontWeight={{ xs: 700, lg: 800 }}
          lineHeight={{ xs: "35px", md: "48px" }}
          color={"#000"}
          align="center"
        >
          {t("mostFeatures")}
        </Typography>
      </Box>

      <Grid container spacing={2} mb={{ xs: 3, lg: "167px" }}>
        <Grid item xs={12} lg={6}>
          <Box position={"relative"}>
            <Box
              width={{ xs: "100%", lg: "604px" }}
              height={{ xs: "100%", lg: "440px" }}
              zIndex={2}
              position="relative"
            >
              <img src={Dashboard} alt="" width={"100%"} height={"100%"} />
            </Box>
            <Box
              position={"absolute"}
              bottom={-22}
              left={-23}
              display={{ xs: "none", md: "block" }}
              zIndex={1}
            >
              <img
                src={followerImg}
                alt=""
                style={{
                  transform: i18n.language === "en" ? "scaleX(-1)" : "none",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          mt={{ xs: 3, md: "" }}
        >
          <Typography
            color={"#000"}
            fontSize={{ xs: "24px", md: "32px" }}
            fontWeight={{ xs: 700, lg: 800 }}
            lineHeight={{ xs: "35px", md: "48px" }}
            mb={"15px"}
          >
            {t("clickReceiveData")}
          </Typography>
          <Typography
            color={"#333"}
            fontSize={{ xs: "16px", md: "20px" }}
            fontWeight={500}
            lineHeight={{ xs: "24px", md: "34px" }}
            maxWidth={{ xs: "100%", lg: "580px" }}
          >
            {t("seeNetProfits")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={6}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Typography
            color={"#000"}
            fontSize={{ xs: "24px", md: "32px" }}
            fontWeight={{ xs: 700, lg: 800 }}
            lineHeight={{ xs: "35px", md: "48px" }}
            mb={"15px"}
            maxWidth={{ xs: "100%", lg: "617px" }}
            sx={{ textAlign: { xs: "center", md: "start" } }}
          >
            {t("understandSituationAtWork")}
          </Typography>
          <Typography
            color={"#333"}
            fontSize={{ xs: "16px", md: "20px" }}
            fontWeight={500}
            lineHeight={{ xs: "24px", md: "34px" }}
            maxWidth={{ xs: "100%", lg: "611px" }}
            sx={{ textAlign: { xs: "center", md: "start" } }}
          >
            {t("knowYouUseEquation")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box position={"relative"}>
            <Box
              width={{ xs: "100%", lg: "678px" }}
              height={{ xs: "100%", lg: "417px" }}
              mt={{ xs: 3, md: "" }}
              zIndex={2}
              position="relative"
            >
              <img src={Settings} alt="" width={"100%"} height={"100%"} />
            </Box>
            <Box
              position={"absolute"}
              bottom={-22}
              left={-23}
              display={{ xs: "none", md: "block" }}
              zIndex={1}
            >
              <img
                src={followerImg}
                alt=""
                style={{
                  transform: i18n.language === "en" ? "scaleX(-1)" : "none",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default JazarSystemPros;
