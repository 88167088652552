import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import AddEditWasteFormula from "../../add-edit-waste-formula";
import { useDeleteWasteFormulaMutation } from "src/api/inventory/waste-formulas";
import { ConfirmPopup } from "src/shared/components/confirm-popup";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { mutate, isPending, status, error } = useDeleteWasteFormulaMutation();

  const handleOpen = () => {
    if (!!row.confirmed_at) {
      setNotifications([
        ...notifications,
        {
          type: "warning",
          message: t("wasteFormulaHasBeenUsed"),
        },
      ]);
      return;
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleOpenEditModal = () => {
    if (!!row.confirmed_at) {
      setNotifications([
        ...notifications,
        {
          type: "warning",
          message: t("wasteFormulaHasBeenUsed"),
        },
      ]);
      return;
    }
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleDelete = () => {
    mutate(row.id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Tooltip title={t("edit")}>
        <IconButton onClick={handleOpenEditModal}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("delete")}>
        <IconButton onClick={handleOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      </Tooltip>

      {openEditModal && (
        <AddEditWasteFormula
          open={openEditModal}
          handleClose={handleCloseEditModal}
          row={row}
          refetch={refetch}
        />
      )}

      {/* confirm delete popup */}
      <ConfirmPopup
        open={open}
        item={""}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
