import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import SettingIcon from "src/assets/svg-icons/side-bar/settings.svg";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import InfoItem from "./info-item";
import TransactionPaymentsTable from "./table";
import { useNavigate, useParams } from "react-router-dom";
import FileItem from "src/shared/components/file-item";
import { downloadFileFromUrl, formatBytes, formatNumber } from "src/utils";
import { useTranslation } from "react-i18next";
import { useGetPurchaseById } from "src/api/purchasing/purchasing";
import TransactionItemsTable from "./items-table";

const PurchasingTransactionDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");
  const { t: purchasingT } = useTranslation("purchasing");

  const { data, isLoading, refetch } = useGetPurchaseById(id || "");

  const navigateToOperation = () => {
    navigate("/purchasing");
  };

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={SettingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {purchasingT("transactionDetails")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToOperation}
          sx={{ cursor: "pointer" }}
        >
          {purchasingT("purchasing")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          sx={{ cursor: "pointer" }}
          onClick={navigateToOperation}
        >
          {purchasingT("transactionDetails")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            {data?.data.reference}
          </Typography>
        </Box>
      </Stack>
      {isLoading && !data ? (
        <Box display={"flex"} justifyContent={"center"} pt={20}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack bgcolor={"#F9FAFB"} spacing={1.5} p={2}>
            <Typography
              fontSize={"14px"}
              color={"#344054"}
              fontWeight={700}
              lineHeight={"18.8px"}
            >
              {generalT("branch")}:
              <Typography
                component={"span"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"18.8px"}
              >
                {" "}
                {data?.data.branch.name}
              </Typography>
            </Typography>

            <InfoItem
              title={purchasingT("supplier")}
              text={`${data?.data.supplier?.name || ""}`}
              label={t("dueDate")}
              value={`${data?.data.date}`}
            />

            <InfoItem
              title={generalT("subtotal")}
              text={`${formatNumber(data?.data.amount)}`}
              label={generalT("status")}
              value={
                data?.data.status === "Paid"
                  ? t("paid")
                  : data?.data.status === "Partly Paid"
                  ? t("partiallyPaid")
                  : data?.data.status === "UnPaid"
                  ? t("unpaid")
                  : t("overpaid")
              }
            />
            <InfoItem
              title={generalT("tax")}
              text={`${formatNumber(data?.data.tax)}`}
              // label={t("invoiceBalance")}
              // value={`${formatNumber(data?.data.total_paid)}`}
              label={generalT("totalWithTax")}
              value={data?.data.total}
            />
            <InfoItem
              title={t("invoiceNumber")}
              text={`${data?.data.invoice_number || ""}`}
              label={generalT("createdBy")}
              value={`${data?.data.created_by.name}`}
            />
            <InfoItem
              title={t("notes")}
              text={`${data?.data.note || ""}`}
              label={t("dueDate")}
              value={`${data?.data.date}`}
            />
            <InfoItem
              title={generalT("createdAt")}
              text={`${data?.data.created_at || ""}`}
              label={t("invoiceBalance")}
              value={`${formatNumber(data?.data.total_remaining)}`}
            />
            <InfoItem title={t("attachedBill")} label={""} value={""} />
            <Stack gap={3} direction={"row"} maxWidth={"50%"} flexWrap={"wrap"}>
              {data?.data.files.map((attachment: any) => {
                return (
                  <FileItem
                    isDeletable={false}
                    key={attachment.id}
                    name={`${attachment.file.name} .${attachment.file.extension}`}
                    info={formatBytes(attachment.file.size)}
                    isDownloadable
                    onDownload={() => {
                      downloadFileFromUrl(
                        attachment.file.url,
                        attachment.file.name
                      );
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
          <TransactionPaymentsTable
            list={data.data.payments}
            isLoading={isLoading}
            refetch={refetch}
          />
          <TransactionItemsTable
            list={data.data.items}
            isLoading={isLoading}
            refetch={refetch}
          />
        </>
      )}
    </Stack>
  );
};

export default PurchasingTransactionDetails;
