import SuppliesTabs from "./tabs";
import { Stack } from "@mui/material";

const Supplies = () => {
  return (
    <Stack maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <SuppliesTabs />
    </Stack>
  );
};

export default Supplies;
