import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetPaymentMethods } from "src/api/generic";
import { useAddOCTransactionPaymentMutation } from "src/api/operation-cost/transactions";
import { LoadingButton } from "@mui/lab";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  amount: yup.string().required("Required"),
  payment_method_id: yup.string().required("Required"),
  date: yup.string().required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetch: Function;
  id: number;
}

const AddTransactionPaymentPopup = (props: IProps) => {
  const { open, handleClose, refetch, id } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [files, setFiles] = useState<any>([]);
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");

  //APIS
  const { data: paymentsData } = useGetPaymentMethods();
  const { mutate, status, isPending, error } =
    useAddOCTransactionPaymentMutation();

  //React Hook Form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onClose = () => {
    handleClose();
    reset();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const dataToSend: any = {
      ...data,
      amount: +data.amount,
      invoice_id: id,
    };
    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    mutate(formData);
  };

  //Add OC transaction payment success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  console.log({ errors });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 547 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {"Confirm transaction "}
          </Typography>
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* payment method */}
            <FormControl
              fullWidth
              size="small"
              error={!!errors.payment_method_id}
            >
              <InputLabel id="demo-simple-select-label">
                {t("paymentMethod")}
              </InputLabel>
              <Controller
                name="payment_method_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("paymentMethod")}
                  >
                    {paymentsData?.data
                      ?.filter((pay) => pay.status)
                      .map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={`${id}`}>
                            {name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.payment_method_id?.message}
              </FormHelperText>
            </FormControl>

            {/* date */}
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <TextField
                  // sx={{ flexBasis: "50%" }}
                  type="date"
                  label={t("paymentDate")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {/* Amount */}
            <Stack>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    fullWidth
                    label={t("amount")}
                    size="small"
                    {...field}
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                )}
              />
            </Stack>
          </Stack>
          {/* files */}
          <Box width={"100%"} mt={2}>
            <FilePondUploader
              onUpload={(e) => setFiles(e)}
              maxFiles={3}
              maxFileSize={3}
            />
          </Box>
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={onClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending}
            >
              {"Confirm"}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddTransactionPaymentPopup;
