import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ItemInfo from "../../item-info";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  aggregators,
  inventory,
  operatingCosts,
  questions,
} from "src/pages/prices-page/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const Months = ({ isYear }: { isYear?: boolean }) => {
  const { t, i18n } = useTranslation("prices");
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          p: { xs: 2, lg: 4 },
          width: "100%",
          borderRadius: "20px",
          boxShadow: "none",
          mb: "29px",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          mb={"16px"}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Typography
            fontSize={"36px"}
            color={"#2B3674"}
            lineHeight={"44px"}
            fontWeight={700}
            mb={{ xs: 2, md: 0 }}
          >
            {isYear ? 2000 : 300}
            {t("sar")}.{" "}
            <Typography
              component={"span"}
              color={"#474747"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"28px"}
            >
              {isYear ? 12 : 3}
              {t("months")}
            </Typography>
          </Typography>

          <Button variant="contained">{t("subscribeFree")}</Button>
        </Box>
        <Typography
          color={"#2B3674"}
          fontSize={"20px"}
          fontWeight={700}
          lineHeight={"28px"}
          mb={1}
        >
          {t("thirdPackage")}
        </Typography>
        <Typography color={"#474747"} lineHeight={"20px"}>
          {t("hereYouCanDo")}
        </Typography>
        {/* divider */}
        <Box width={"100%"} height={"1px"} bgcolor={"#E2E2E2"} my={"18px"} />
        <Typography
          color={"#2B3674"}
          fontWeight={600}
          mb={1}
          lineHeight={"24px"}
        >
          {t("aggregators")}
        </Typography>
        <Stack spacing={1}>
          <ItemInfo title={t("feature1")} />
          <ItemInfo title={t("feature2")} />
          <ItemInfo title={t("feature3")} />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack spacing={1}>
              {/* aggregators */}
              {aggregators.map((item) => (
                <ItemInfo title={t(item)} />
              ))}
              <Typography
                py={1}
                color={"#2B3674"}
                fontWeight={600}
                lineHeight={"24px"}
              >
                {t("inventoryAndCost")}
              </Typography>
              {/* inventory */}
              {inventory.map((item) => (
                <ItemInfo title={t(item)} />
              ))}
              <Typography
                py={1}
                color={"#2B3674"}
                fontWeight={600}
                lineHeight={"24px"}
              >
                {t("operatingCosts")}
              </Typography>
              {/* operating costs */}
              {operatingCosts.map((item) => (
                <ItemInfo title={t(item)} />
              ))}
            </Stack>
          </Collapse>
          <Stack
            direction={"row"}
            width={"fit-content"}
            spacing={"12px"}
            onClick={handleClick}
            sx={{ cursor: "pointer" }}
            alignItems={"center"}
          >
            <Typography
              color={"#FF7F29"}
              fontWeight={600}
              lineHeight={"24px"}
              sx={{ textDecoration: "underline" }}
            >
              {open ? t("seeLessFeatures") : t("seeAllFeatures")}
            </Typography>
            <ExpandCircleDownIcon
              sx={{ fontSize: "30px", transform: open ? "scaleY(-1)" : "" }}
              color="primary"
            />
          </Stack>
        </Stack>
        <Box display={"flex"} justifyContent={"flex-end"} mt={4}>
          <Button variant="contained">{t("subscribeFree")}</Button>
        </Box>
      </Paper>
      <Paper
        elevation={2}
        sx={{
          p: { xs: 2, lg: "32px 24px" },
          width: "100%",
          borderRadius: "20px",
          boxShadow: "none",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "26px",
        }}
      >
        <Typography
          fontSize={{ xs: "22px", md: "36px" }}
          fontWeight={700}
          lineHeight={{ xs: "20px", lg: "44px" }}
          color={"#2B3674"}
        >
          {t("purchasingPackage")}
        </Typography>
        <Box
          bgcolor={"#FF4545"}
          p={"8px 30px"}
          borderRadius={"15px"}
          maxHeight={"44px"}
        >
          <Typography fontWeight={700} lineHeight={"28px"} color={"#FFFFFF"}>
            {t("soon")}
          </Typography>
        </Box>
      </Paper>

      <Paper
        elevation={2}
        sx={{
          p: { xs: 2, lg: "32px 24px" },
          width: "100%",
          borderRadius: "20px",
          boxShadow: "none",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: { xs: "60px", lg: "218px" },
        }}
      >
        <Typography
          fontSize={{ xs: "22px", md: "36px" }}
          fontWeight={700}
          lineHeight={{ xs: "20px", lg: "44px" }}
          color={"#2B3674"}
        >
          {t("professionalPackage")}
        </Typography>
        <Box bgcolor={"#FF4545"} p={"8px 30px"} borderRadius={"15px"}>
          <Typography fontWeight={700} lineHeight={"28px"} color={"#FFFFFF"}>
            {t("soon")}
          </Typography>
        </Box>
      </Paper>
      {/* frequently Questions */}
      <Grid container>
        <Grid item xs={12} lg={5}>
          <Typography
            fontSize={{ xs: "22px", lg: "40px" }}
            fontWeight={700}
            lineHeight={{ xs: "26px", lg: "50px" }}
            color={"#000000"}
            maxWidth={{ xs: "100%", lg: "332px" }}
          >
            {t("frequentlyQuestions")}
            <Typography
              component={"span"}
              color={"#FF9954"}
              fontSize={{ xs: "30px", lg: "50px" }}
              fontWeight={{ xs: 400, lg: 700 }}
            >
              {i18n.language === "en" ? "?" : "؟"}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={7} display={"flex"} justifyContent={"end"}>
          <Stack spacing={"20px"}>
            {questions.map((item) => {
              return (
                <Accordion
                  elevation={2}
                  sx={{
                    p: { xs: 1.5, lg: "12px 24px" },
                    borderRadius: "20px !important",
                    boxShadow: "none",
                    maxWidth: { xs: "100%", lg: "682px" },
                    "&:before": {
                      display: "none",
                    },
                    "&.MuiAccordion-root": {
                      borderTop: "none",
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      fontSize={{ xs: "16px", lg: "22px" }}
                      fontWeight={{ xs: 600, lg: 800 }}
                      lineHeight={{ xs: "20px", lg: "30px" }}
                      color={"#333333"}
                    >
                      {t(item.question)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      color={"#585C61"}
                      fontSize={"18px"}
                      lineHeight={"24px"}
                    >
                      {t(item.answer)}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default Months;
