import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  SwitchProps,
  Typography,
} from "@mui/material";
import sealIcon from "src/assets/svg-icons/info.svg";
import AvgOrderIcon from "src/assets/svg-icons/aggregators/avgo_order.svg";
import { styled } from "@mui/material/styles";
import CustomList from "src/shared/components/list";
import { IListItemProps } from "src/shared/components/list-item";
import { IAvgOrderData } from "src/types/aggregators";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: " #66E",
        opacity: 1,
        border: 2,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    backgroundColor: "#D0D5DD",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AvgOrder = ({ data }: { data: IAvgOrderData | undefined }) => {
  const { t } = useTranslation("aggregators");
  const [withDeliveryCost, setWithDeliveryCost] = useState(true);
  const listData: IListItemProps[] = [
    {
      label: t("avgCogs"),
      value: `${formatNumber(Number(data?.avg_cogs.toFixed(2)))}`,
    },
    {
      label: t("avgAggFee"),
      value: `${formatNumber(Number(data?.avg_aggregator_fee.toFixed(2)))}`,
    },
    {
      label: t("avgDeliveryCost"),
      value: withDeliveryCost
        ? `${formatNumber(
            Number(data?.avg_aggregator_delivery_cost.toFixed(2))
          )}`
        : "0",
    },
    {
      label: t("aggBankFee"),
      value: `${formatNumber(
        Number(data?.avg_aggregator_bank_fee.toFixed(2))
      )}`,
    },
    {
      label: t("avgCompensation"),
      value: `${formatNumber(Number(data?.avg_compensation.toFixed(2)))}`,
    },
    {
      label: t("avgOtherFee"),
      value: `${formatNumber(Number(data?.avg_other_fee.toFixed(2)))}`,
    },
    {
      label: t("avgTotalCosts"),
      value: withDeliveryCost
        ? `${formatNumber(Number(data?.avg_total_costs.toFixed(2)))}`
        : `${formatNumber(+(
            Number(data?.avg_total_costs) -
            Number(data?.avg_aggregator_delivery_cost)
          ).toFixed(2))}`,
      divider: "hidden",
    },
  ];

  return (
    <Stack
      spacing={3}
      p={"18px"}
      border={"1px solid #EAECF0"}
      height={{ xs: "100%", lg: "617px" }}
      borderRadius={1}
    >
      <Box
        p={"12px"}
        bgcolor={"#F9FAFB"}
        border={"1px solid #EAECF0"}
        display={"flex"}
        justifyContent={"space-between"}
        borderRadius={1}
        alignItems={"center"}
      >
        <Box>
          <Stack gap={"10px"} direction={"row"} mb={1}>
            <Typography color={"#667085"} lineHeight={"19.2px"}>
              {t("avgOrder")}
            </Typography>
            <img src={sealIcon} alt="" width={"16px"} />
          </Stack>
          <Typography
            fontSize={"23px"}
            lineHeight={"27.6px"}
            fontWeight={600}
            color={"#101828"}
          >
            {t("sar")} {data?.avg_sales.toFixed(2)}
          </Typography>
          <FormControlLabel
            control={
              <CustomSwitch
                sx={{ m: 1 }}
                checked={withDeliveryCost}
                onChange={(e) => setWithDeliveryCost(e.target.checked)}
              />
            }
            label={
              <Typography
                fontSize={"18px"}
                color={"#101828"}
                lineHeight={"21.6px"}
              >
                {t("includeDeliveryCost")}
              </Typography>
            }
          />
        </Box>
        <img src={AvgOrderIcon} alt="" />
      </Box>
      {/* list item */}
      <CustomList listData={listData} />
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography color={"#1D2939"} lineHeight={"19.2px"}>
          {t("profit")}
        </Typography>
        <Stack direction={"row"} gap={"10px"}>
          <Typography color={"#1D2939"} lineHeight={"19.2px"} fontWeight={700}>
            (
            {withDeliveryCost
              ? data?.profit_percentage.toFixed(2)
              : data?.profit_percentage_without_delivery.toFixed(2)}
            %)
          </Typography>
          <Typography color={"#1D2939"} lineHeight={"19.2px"}>
            {withDeliveryCost
              ? formatNumber(data?.profit as number)
              : formatNumber(+(
                  Number(data?.profit) +
                  Number(data?.avg_aggregator_delivery_cost)
                ).toFixed(2))}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default AvgOrder;
