import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import {
  IMoneyOwnResponse,
  IProfitResponse,
  ITotalInventoryResponse,
  ITotalPurchaseResponse,
} from "src/types/dashboard";
import apiClient from "./apiClient";

interface IParams {
  branches?: number[];
  start_date: string;
  end_date: string;
  payment_methods?: number[]
}

export const useGetProfitChartData = (params: IParams) =>
  useQuery<any, APIErrorResponse, IProfitResponse>({
    queryKey: ["get-profile-chart-data"],
    queryFn: async () => {
      return apiClient.get(`/api/sales/summary-report`, { params: params });
    },
  });

export const useGetMoneyOwnChartData = (params: IParams) =>
  useQuery<any, APIErrorResponse, IMoneyOwnResponse>({
    queryKey: ["get-money-own-chart-data"],
    queryFn: async () => {
      return apiClient.get(`/api/sales/tax-report`, { params: params });
    },
  });

export const useGetTotalInventoryChartData = (params: IParams) =>
  useQuery<any, APIErrorResponse, ITotalInventoryResponse>({
    queryKey: ["get-total-inventory-chart-data"],
    queryFn: async () => {
      return apiClient.get(`/api/sales/inventory-report`, { params: params });
    },
  });

export const useGetTotalPurchaseChartData = (params: IParams) =>
  useQuery<any, APIErrorResponse, ITotalPurchaseResponse>({
    queryKey: ["get-total-purchase-chart-data"],
    queryFn: async () => {
      return apiClient.get(`/api/sales/purchase-report`, { params: params });
    },
  });
