import { ReactComponent as DashboardIcon } from "src/assets/svg-icons/side-bar/dashboard.svg";
import { ReactComponent as AggregatorsIcon } from "src/assets/svg-icons/side-bar/aggregators.svg";
import { ReactComponent as ReportsIcon } from "src/assets/svg-icons/side-bar/reports.svg";
import { ReactComponent as OperationIcon } from "src/assets/svg-icons/side-bar/operation.svg";
import { ReactComponent as SettingsIcon } from "src/assets/svg-icons/side-bar/settings.svg";
import { ReactComponent as InventoryIcon } from "src/assets/svg-icons/side-bar/inventory.svg";
import { ReactComponent as MatchingIcon } from "src/assets/svg-icons/side-bar/matching.svg";

export const sideBarItems = [
  {
    label: "dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    label: "aggregators",
    icon: <AggregatorsIcon />,
    path: "/aggregators",
  },
  {
    label: "purchasing",
    icon: <MatchingIcon />,
    path: "/purchasing",
  },
  {
    label: "operationCost",
    icon: <OperationIcon />,
    path: "/operation",
  },
  {
    label: "inventory",
    icon: <InventoryIcon />,
    path: "/inventory",
  },
  {
    label: "3rdEyeReport",
    icon: <ReportsIcon />,
    path: "/reports",
  },
  {
    label: "settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
];
