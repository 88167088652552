import { Box, Stack, Typography } from "@mui/material";
import InventoryIcon from "src/assets/svg-icons/side-bar/inventory.svg";
import { useTranslation } from "react-i18next";
import InventoryTabs from "./tabs";

const Inventory = () => {
  const { t } = useTranslation("inventory");

  return (
    <Stack gap={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={InventoryIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("inventory")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <InventoryTabs />
    </Stack>
  );
};

export default Inventory;
