import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "src/assets/svg-icons/logo.svg";
import phoneIcon from "src/assets/svg-icons/home/phone.svg";
import EmailIcon from "src/assets/svg-icons/home/email.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  const { t } = useTranslation("home");
  const currentYear = new Date().getFullYear();

  return (
    <Stack
      bgcolor={"#FF9954"}
      p={{ xs: "16px", lg: "69px 108px 94px 144px" }}
    >
      <Grid container>
        <Grid item xs={12} lg={4.5}>
          <Box mb={{ xs: 2, lg: "30px" }}>
            <img src={logo} alt="" />
          </Box>
          <Typography
            fontSize={"14px"}
            fontWeight={{ xs: 400, lg: 600 }}
            lineHeight={"19.6px"}
            color={"#FFF"}
            mb={"11px"}
          >
            {t("jazarSystemSubtitle")}
          </Typography>
          <Stack spacing={3} direction={"row"}>
            <LinkedInIcon style={{ color: "#FFF" }} />
            <TwitterIcon style={{ color: "#FFF" }} />
            <FacebookIcon style={{ color: "#FFF" }} />
            <WhatsAppIcon style={{ color: "#FFF" }} />
          </Stack>
        </Grid>
        <Grid item xs={0} lg={1.5} />
        <Grid item xs={8} lg={3} display={"flex"} alignItems={"center"}>
          <Stack spacing={3}>
            <Stack direction={"row"} spacing={"12px"}>
              <img src={phoneIcon} alt="" width={"30px"} />
              <Box>
                <Typography fontSize={"14px"} color={"#FFF"}>
                  {t("haveAQuestion")}
                </Typography>
                <Typography fontSize={"16px"} fontWeight={700} color={"#FFF"}>
                  310-437-2766
                </Typography>
              </Box>
            </Stack>

            <Stack direction={"row"} spacing={"12px"}>
              <img src={EmailIcon} alt="" width={"30px"} />
              <Box>
                <Typography fontSize={"14px"} color={"#FFF"}>
                  {t("contactUsWith")}
                </Typography>
                <Typography fontSize={"16px"} fontWeight={700} color={"#FFF"}>
                  unreal@outlook.com
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4} lg={3}>
          <Stack spacing={0.5}>
            <Typography
              fontSize={"24px"}
              color={"#FFF"}
              fontWeight={700}
              lineHeight={"36px"}
            >
              {t("ourPages")}
            </Typography>
            <Typography fontSize={"18px"} color={"#FFF"} lineHeight={"27px"}>
              {t("mainSite")}
            </Typography>
            <Typography fontSize={"18px"} color={"#FFF"} lineHeight={"27px"}>
              {t("ourServices")}
            </Typography>
            <Typography fontSize={"18px"} color={"#FFF"} lineHeight={"27px"}>
              {t("prices")}
            </Typography>
            <Typography fontSize={"18px"} color={"#FFF"} lineHeight={"27px"}>
              {t("aboutUs")}
            </Typography>
            <Typography fontSize={"18px"} color={"#FFF"} lineHeight={"27px"}>
              {t("contactUs")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      {/* divider */}
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
        <Box
          bgcolor={"#FFFFFF80"}
          width={"95%"}
          height={"1px"}
          mt={"36px"}
          mb={"24px"}
        />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"95%"}
        >
          <Typography
            fontSize={"14px"}
            color={"#FFF"}
            lineHeight={"10px !important"}
          >
            <Typography component={"span"} fontSize={"18px"} color={"#FFF"}>
              ©
            </Typography>
            {t("allRightsReserved")} {currentYear} {t("forJazr")}
          </Typography>
          <Typography fontSize={"14px"} color={"#FFF"}>
            {t("privacyPolicy")}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default Footer;
