import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
}

interface ISupplierBody {
  id?: number;
  name: string;
  email?: string;
  phone?: string;
  delivery_days?: number;
  start_balance?: number;
  bank_account?: string;
}

export const useGetSuppliersList = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-suppliers-list", params?.page],
    queryFn: async () => {
      return apiClient.get(`/api/suppliers`, { params: params || {} });
    },
  });

export const useGetDeletedSuppliersList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-deleted-suppliers-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/suppliers/deleted-suppliers`, { params });
    },
  });

export const useAddSupplierMutation = () =>
  useMutation<void, APIErrorResponse, ISupplierBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/suppliers`, data);
    },
  });

export const useUpdateSupplierMutation = () =>
  useMutation<void, APIErrorResponse, ISupplierBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/suppliers/${id}`, rest);
    },
  });

export const useDeleteSupplierMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/suppliers/${id}`);
    },
  });

export const useRestoreSupplierMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.post(`/api/suppliers/restore-supplier/${id}`);
    },
  });
