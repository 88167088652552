import { Paper, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { ITotalInventoryData } from "src/types/dashboard";
import { formatLongNumbers, formatNumber } from "src/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";

const colors = ["#FF8B3D", "#1A1AE6"];

const InventoryChart = ({
  data,
}: {
  data: ITotalInventoryData | undefined;
}) => {
  const daysArr: string[] = [];
  const startValuesArr: number[] = [];
  const endValuesArr: number[] = [];
  const { t } = useTranslation("dashboard");

  data?.days.forEach((item) => {
    daysArr.push(moment(item.day).format("DD / MM"));
    startValuesArr.push(item.data.start);
    endValuesArr.push(item.data.end);
  });

  const series = [
    {
      data: startValuesArr,
      name: t("inventoryBeginning"),
    },
    {
      data: endValuesArr,
      name: t("inventoryEnding"),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    noData: {
      text: t("noData"),
    },
    colors: colors,
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
      },
    },
    chart: {
      toolbar: {
        show: true, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },
    xaxis: {
      categories: daysArr,
      labels: {
        style: {
          colors: "#667085",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value: number): string => formatNumber(+value)?.toString(),
      },
    },
    yaxis: {
      labels: {
        formatter: formatLongNumbers,
      },
    },
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        boxShadow: " 0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        p: 3,
      }}
    >
      <Stack>
        <Typography
          lineHeight={"22px"}
          textTransform={"capitalize"}
          fontWeight={500}
          color={"#334155"}
        >
          {t("inventoryItems")}
        </Typography>
        <Typography
          fontWeight={500}
          fontSize={"14px"}
          color={"#FF8833"}
          lineHeight={"22px"}
        >
          {t("inventoryTurnover")} = {`${data?.turn_over}`}X
        </Typography>
      </Stack>

      <ReactApexChart options={options} series={series} type="bar" />
    </Paper>
  );
};

export default InventoryChart;
