import { Stack, Tooltip, Typography } from "@mui/material";
import incorrectIcon from "src/assets/svg-icons/incorrect.svg";
import correctIcon from "src/assets/svg-icons/correct.svg";

interface IProps {
  title: string;
  subtitle: string;
  operator?: string;
  subtitleColor: string;
  iconType?: "incorrect" | "correct";
  tooltip?: string 
}
const EquationItem = (props: IProps) => {
  const {
    title,
    subtitle,
    operator = "",
    subtitleColor,
    iconType = "correct",
    tooltip = "" 
  } = props;
  return (
    <Tooltip
      title={tooltip}
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <Stack p={{ xs: "5px", sm: "10px" }} direction={"row"} gap={"8px"}>
        {iconType === "incorrect" ? (
          <img src={incorrectIcon} alt="" />
        ) : (
          <img src={correctIcon} alt="" />
        )}

        <Stack>
          <Typography
            sx={{ fontSize: { xs: "11px", md: "16px" }, width: "max-content" }}
            lineHeight={"19.2px"}
            color={"#475467"}
            fontWeight={600}
          >
            {title}
          </Typography>
          <Typography
            sx={{ fontSize: { xs: "10px", md: "13px" }, width: "max-content" }}
            fontWeight={700}
            lineHeight={"15.6px"}
            color={subtitleColor}
          >
            {subtitle}
          </Typography>
        </Stack>
        <Typography
          fontSize={"29px"}
          fontWeight={600}
          lineHeight={"34.8px"}
          px={"6px"}
        >
          {operator}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
export default EquationItem;
