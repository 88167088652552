import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import HighProductIcon from "src/assets/svg-icons/aggregators/high_product.svg";
import LowProductIcon from "src/assets/svg-icons/aggregators/low_product.svg";
import MostProductIcon from "src/assets/svg-icons/aggregators/most_product.svg";
import ProductItem from "src/pages/aggregators/tabs/Summary/products-statistics/product-item";
import { IProductStatistic } from "src/types/aggregators";

const ProductsStatistics = ({
  highProfitProducts,
  lowProfitProducts,
  popularProfitProducts,
}: {
  highProfitProducts: IProductStatistic[] | undefined;
  lowProfitProducts: IProductStatistic[] | undefined;
  popularProfitProducts: IProductStatistic[] | undefined;
}) => {
  const { t } = useTranslation("aggregators");
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <ProductItem
            img={HighProductIcon}
            title={t("highProfitProducts")}
            data={highProfitProducts}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <ProductItem
            img={LowProductIcon}
            title={t("lowProfitProducts")}
            data={lowProfitProducts}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <ProductItem
            img={MostProductIcon}
            title={t("mostPopularProducts")}
            data={popularProfitProducts}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductsStatistics;
