import { Stack, Typography } from "@mui/material";
interface IProps {
  label: string;
  value: string;
}
const Item = (props: IProps) => {
  const { label, value } = props;
  return (
    <Stack direction={"row"} alignItems={"center"} gap={0.5}>
      <Typography
        color={"#344054"}
        fontSize={"14px"}
        fontWeight={700}
        lineHeight={"16.8px"}
      >
        {label}:
      </Typography>
      <Typography
        color={"#344054"}
        fontSize={"14px"}
        lineHeight={"16.8px"}
        style={{ marginLeft: "4px" }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default Item;
