import { Stack } from "@mui/material";
import CustomListListItem, { IListItemProps } from "../list-item";

interface IListProps {
  listData: IListItemProps[];
}
const CustomList = (props: IListProps) => {
  const { listData } = props;

  return (
    <Stack spacing={1}>
      {listData.map((item) => (
        <CustomListListItem
          label={item.label}
          value={item.value}
          icon={item.icon === "show" ? "show" : "hidden"}
          divider={item.divider === "hidden" ? "hidden" : "show"}
          iconColor={item.iconColor === "black" ? "black" : "gray"}
        />
      ))}
    </Stack>
  );
};
export default CustomList;
